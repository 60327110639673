import { Box, Typography, Grid, Container } from '@mui/material';
import React from 'react';
import { Blue, Br, SectionHeader } from 'components';

export const ShippingIssuesLogos = () => {
	const logos = [
		{ src: '/Theft.svg', text: 'Theft' },
		{ src: '/Damaged.svg', text: 'Damaged Items' },
		{ src: '/Lost.svg', text: 'Lost' },
		{ src: '/Return.svg', text: 'Return to Sender' },
		{ src: '/Beyond.svg', text: '& Beyond' },
	];

	return (
		<Box textAlign="center" sx={{ my: { xs: 6, md: 8 } }}>
			<Container>
				<SectionHeader
					fontSize={{ xs: '22px !important', sm: '32px !important' }}
					lineHeight={{ xs: '26px !important', sm: '38px !important' }}
					mt="0px !important"
					gutterBottom
				>
					We resolve all your <Br xs md={false} />
					<Blue>major shipping issues</Blue> for you
				</SectionHeader>

				<Grid
					container
					flexWrap="wrap"
					justifyContent={{ xs: 'center', sm: 'space-evenly' }}
				>
					{logos.map((logo, index) => {
						return (
							<Grid
								item
								xs={4}
								md
								key={index}
								width={110}
								alignItems="center"
								justifyContent="center"
								sx={{ mt: 4 }}
							>
								<img src={logo.src} width={100} height={100} />
								<Typography
									noWrap
									variant="h5"
									pt={1}
									fontSize={{ xs: 14, md: 12 }}
								>
									{logo.text}
								</Typography>
							</Grid>
						);
					})}
				</Grid>
			</Container>
		</Box>
	);
};
