import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import { Form, Hero } from './components';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Hidden,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Email } from '@mui/icons-material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LoadingIndicator from 'components/LoadingIndicator';
import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = yup.object({
	orderName: yup
		.string()
		.trim()
		.min(2, 'Please enter a valid order number')
		.max(50, 'Please enter a valid order number')
		.required('Please enter your order number'),
	email: yup
		.string()
		.trim()
		.email('Please enter a valid email address')
		.required('Email is required'),
});

export default function FileClaimView() {
	const theme = useTheme();
	const [linkData, setLinkData] = useState();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();
	const [sp] = useSearchParams();

	const onSubmit = async () => {
		setLoading(true);

		const sp = new URLSearchParams({
			orderName: formik.values.orderName,
			email: formik.values.email,
		});

		fetch(`${process.env.REACT_APP_API_URL}/v1/find-order?${sp.toString()}`)
			.then((r) => {
				if (r.status !== 200) throw new Error(r.statusText);
				return r.json();
			})
			.then(setLinkData)
			.catch((e) => {
				console.error(e);
				setError(e.message || true);
			})
			.finally(setLoading);
	};

	const formik = useFormik({
		initialValues: {
			orderName: '',
			email: '',
		},
		validationSchema,
		onSubmit,
	});

	useEffect(() => {
		const cn = sp.get('cn');
		const em = sp.get('em');
		const mn = sp.get('mn');
		const on = sp.get('on');
		const oid = sp.get('oid');
		const mid = sp.get('mid');
		if (oid && mid && on && mn && em && cn) {
			setLinkData({
				_id: oid,
				mid,
				orderName: on,
				merchantName: mn,
				email: em,
				customerName: cn,
			});
		}
		setLoading(false);
	}, [sp]);

	if (linkData) {
		return (
			<>
				<Box
					sx={{
						position: 'relative',
						backgroundColor: theme.palette.alternate.main,
						backgroundImage: `linear-gradient(120deg, ${theme.palette.alternate.dark} 0%, ${theme.palette.background.paper} 100%)`,
						marginTop: -13,
						paddingTop: 13,
					}}
				>
					<Container>
						<Hidden mdDown>
							<Hero />
						</Hidden>
					</Container>
				</Box>

				<Box bgcolor={'alternate.main'}>
					<Container>
						<Form {...{ ...linkData, setLoading }} />
					</Container>
				</Box>

				<LoadingIndicator open={!!loading} />
			</>
		);
	}

	return (
		<Box
			sx={{
				background: 'linear-gradient(#2A415F 0%, #1A2237 100%)',
			}}
		>
			<Container>
				<Hidden mdDown>
					<Hero />
				</Hidden>
			</Container>
			<Container maxWidth="sm">
				<Card>
					<CardHeader
						title="File a Claim"
						subheader="Let's look up your order first"
					/>

					{error && (
						<CardContent>
							<Typography color="red" whiteSpace="break-spaces">
								{"We couldn't find your order! Double check your email and order number.\n\n" +
									'Tip: Make sure you include all symbols with your order number \n(e.g. #12345 instead of 12345, ABC#67890 instead of ABC67890)'}
							</Typography>
						</CardContent>
					)}
					<CardContent>
						<form onSubmit={formik.handleSubmit}>
							<Stack spacing={2}>
								<TextField
									name="email"
									value={formik.values.email}
									onChange={formik.handleChange}
									error={formik.touched.email && Boolean(formik.errors.email)}
									helperText={formik.touched.email && formik.errors.email}
									label="Email"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Email />
											</InputAdornment>
										),
									}}
								/>
								<TextField
									label="Order Number"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<ReceiptIcon />
											</InputAdornment>
										),
									}}
									name="orderName"
									value={formik.values.orderName}
									onChange={(e) => {
										e.target.value = e.target.value.replace(' ', '');
										return formik.handleChange(e);
									}}
									error={
										formik.touched.orderName && Boolean(formik.errors.orderName)
									}
									helperText={
										formik.touched.orderName && formik.errors.orderName
									}
								/>
								<Button
									disabled={!(formik.values.email && formik.values.orderName)}
									variant="contained"
									type="submit"
								>
									Find Order
								</Button>
							</Stack>
						</form>
					</CardContent>
				</Card>
			</Container>
			<LoadingIndicator open={!!loading} />
		</Box>
	);
}
