import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

export default function Br({ xs, sm, md, lg, xl }) {
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.up('xs'));
	const isSm = useMediaQuery(theme.breakpoints.up('sm'));
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const isLg = useMediaQuery(theme.breakpoints.up('lg'));
	const isXl = useMediaQuery(theme.breakpoints.up('xl'));

	if (isXl) {
		if (xl ?? lg ?? md ?? sm ?? xs) return <br />;
	} else if (isLg) {
		if (lg ?? md ?? sm ?? xs) return <br />;
	} else if (isMd) {
		if (md ?? sm ?? xs) return <br />;
	} else if (isSm) {
		if (sm ?? xs) return <br />;
	} else if (isXs && xs) return <br />;

	return null;
}

Br.propTypes = {
	xs: PropTypes.bool,
	sm: PropTypes.bool,
	md: PropTypes.bool,
	lg: PropTypes.bool,
	xl: PropTypes.bool,
};
