import React from 'react';
import { Box, Card, CardMedia, Container } from '@mui/material';

export default function PhotoSection2() {
	return (
		<Box
			sx={{
				pt: { xs: 4, sm: 8, md: 10 },
				pb: { xs: 4, sm: 8, md: 10 },
				background: ' #E3EDFF',
				textAlign: 'center',
			}}
		>
			<Container>
				<Card
					sx={{
						boxShadow: 'none',
						width: '100%',
					}}
				>
					<CardMedia
						component="img"
						src="DedCoolProduct3.png"
						sx={{
							backgroundColor: '#E3EDFF',
						}}
					/>
				</Card>
			</Container>
		</Box>
	);
}
