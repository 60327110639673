import React from 'react';
import Slider from 'react-slick';
import { Stack } from '@mui/material';
import { useBreakpoint } from 'components';

export default function LogoSection() {
	// List of logos (Add more logos as needed)
	const logos = [
		{ src: '/pit-viper.svg' },
		{ src: '/DedCoolLogo.png' },
		{ src: '/Sheets.svg' },
		{ src: '/Axe.svg' },
		{ src: '/Lola.svg' },
		{ src: '/PACK.svg' },
		{ src: '/Slrrrp.svg' },
	];

	/** @type {import("react-slick").Settings} */
	const settings = {
		arrows: false,
		dots: false,
		infinite: true,
		speed: 5000,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 0,
		cssEase: 'linear',
	};

	const bp = useBreakpoint();

	return (
		<Stack>
			<Stack height={80} display={{ xs: 'inherit', sm: 'none' }}>
				<Slider {...settings}>
					{logos.map((logo, index) => (
						<Stack
							key={index}
							height={80}
							width={80}
							justifyContent="center"
							alignItems="center"
							display="flex !important"
						>
							<img
								src={logo.src}
								style={{
									height: 80,
									width: 80,
								}}
							/>
						</Stack>
					))}
				</Slider>
			</Stack>
			<Stack
				direction="row"
				display={{ xs: 'none', sm: 'flex' }}
				justifyContent="space-around"
				sx={{ px: 3 }}
				height={bp.isMd ? 90 : bp.isLg ? 126 : 70}
			>
				{logos.map((logo, index) => (
					<Stack
						key={index}
						justifyContent="center"
						textAlign="center"
						display="flex"
						sx={{
							height: '100%',
							width: {
								xs: 50,
								sm: 60,
								md: 80,
								lg: 100,
								xl: 110,
							},
						}}
					>
						<img
							src={logo.src}
							style={{
								maxHeight: 100,
								maxWidth: 100,
							}}
						/>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
}
