import React from 'react';
import { Stack } from '@mui/material';
import Hero from './Hero';
import DataSection from './DataSection';
import FromTheClient from './FromTheClient';
import NoMoreComplications from './NoMoreComplications';
import PhotoSection1 from './PhotoSection1';
import PhotoSection2 from './PhotoSection2';

export default function CaseStudyView() {
	return (
		<Stack>
			<Hero />
			<DataSection />
			<PhotoSection1 />
			<FromTheClient />
			<PhotoSection2 />
			<NoMoreComplications />
		</Stack>
	);
}
