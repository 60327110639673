import React from 'react';
import {
	Card,
	CardMedia,
	Container,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import { Blue, Br, useBreakpoint } from 'components';

export default function AddsValue() {
	const bp = useBreakpoint();
	return (
		<Container sx={{ pt: { xs: 8, md: 10 }, pb: { xs: 8, md: 10 } }}>
			<Grid container spacing={{ xs: 4, md: 10 }}>
				<Grid item xs={12} md={6}>
					<Card sx={{ borderRadius: 10 }}>
						<CardMedia component="img" src="PackDogImage.png" />
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Stack spacing={4} height="100%" justifyContent="center">
						<Typography variant={bp.isSm ? 'h4' : 'h5'} fontWeight={800}>
							SavedBy
							<Blue px={0}> adds value on Day 1, </Blue>
							<Br xs />
							and is completely free to use forever.
						</Typography>

						<Typography>
							Pack Leashes understands that customer service is a pillar of any
							successful business, that’s what made SavedBy a no-brainer. Not
							only did Pack save hours of Customer Service time, it also saved
							100% of the previously lost revenues, increased customer trust,
							and boosted overall conversion.
						</Typography>
						<Typography>
							By focusing on the customer’s experience, Pack Leashes has seen
							the returns throughout the entire sales funnel: better customer
							experiences, higher customer reviews, less time spent on customer
							service shipping issues, and ultimately boosting the bottom line.{' '}
							<b>All at no additional cost.</b>
						</Typography>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	);
}
