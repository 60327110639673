import React from 'react';
import { CardContent, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function TrackingStatus({
	tracking_status = {},
	address_from: from,
	address_to: to,
	eta,
}) {
	const { status, status_date, status_details, substatus } = tracking_status;

	return status ? (
		<CardContent sx={{ px: 6 }}>
			<Stack>
				<Typography color="goldenrod" variant="h4">
					{substatus?.code === 'out_for_delivery' ? 'OUT FOR DELIVERY' : status}
				</Typography>
				<Typography color="goldenrod" variant="caption">
					{status_details}
				</Typography>
				{from && to && (
					<Typography>
						From{' '}
						<b>
							{from.state}, {from.country}
						</b>{' '}
						to{' '}
						<b>
							{to.state}, {to.country}
						</b>
					</Typography>
				)}
				<Typography color={style.card.textColor}>
					{status === 'DELIVERED' ? (
						<>
							Delivered: <b>{new Date(status_date).toLocaleString()}</b>
						</>
					) : eta ? (
						<>
							Estimated delivery:{' '}
							<b>
								{new Date(eta).toLocaleDateString('en-US', {
									weekday: 'long',
									month: 'short',
									day: 'numeric',
								})}
							</b>
						</>
					) : null}
				</Typography>
			</Stack>
		</CardContent>
	) : (
		<CardContent sx={{ px: 6, py: 10 }}>
			<Stack>
				<Typography color="goldenrod" variant="h4">
					No tracking updates yet
				</Typography>
				<Typography>
					<b>Please check back later</b>
				</Typography>
			</Stack>
		</CardContent>
	);
}

const style = {
	card: {
		bgcolor: 'white',
		textColor: 'black',
		dividerColor: 'gray',
	},
	page: {
		bgcolor: false,
	},
	logoCard: {
		bgcolor: 'black',
	},
};

TrackingStatus.propTypes = {
	tracking_status: PropTypes.object,
	address_from: PropTypes.object,
	address_to: PropTypes.object,
	eta: PropTypes.string,
};

export default TrackingStatus;
