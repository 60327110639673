import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function SectionTitle({ children, ...rest }) {
	return (
		<Typography
			pt={{ xs: 6, md: 8 }}
			color="#38B6FF"
			fontWeight={800}
			variant="h6"
			textAlign="center"
			{...rest}
		>
			{children}
		</Typography>
	);
}

SectionTitle.propTypes = {
	children: PropTypes.string.isRequired,
	rest: PropTypes.object,
};
