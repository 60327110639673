import React from 'react';
import { CardContent, ListItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';

function OrderInfo({ trackingNumber, name }) {
	return (
		<CardContent sx={{ bgcolor: '#fdf4e8', color: 'black' }}>
			<ListItem>
				<ListItemText
					primary="TRACKING NUMBER"
					primaryTypographyProps={{ variant: 'caption' }}
					secondary={trackingNumber}
					secondaryTypographyProps={{
						fontWeight: 600,
						color: 'black',
					}}
				/>
				<ListItemText
					primary="ORDER NUMBER"
					primaryTypographyProps={{ variant: 'caption' }}
					secondary={name}
					secondaryTypographyProps={{
						fontWeight: 600,
						color: 'black',
					}}
				/>
			</ListItem>
		</CardContent>
	);
}

OrderInfo.propTypes = {
	trackingNumber: PropTypes.string,
	name: PropTypes.string,
};

export default OrderInfo;
