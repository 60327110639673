import React from 'react';
import { Button } from '@mui/material';

export default function GetStartedButton() {
	return (
		<Button
			component="a"
			color="primary"
			size="large"
			sx={{
				width: { xs: 150, sm: 'auto', md: 200 },
				fontWeight: 600,
			}}
			href="https://apps.shopify.com/savedby?utm=savedby.io"
		>
			GET STARTED
		</Button>
	);
}
