import React from 'react';
import { Box, Card, CardMedia, Container, Grid, Stack } from '@mui/material';
import { Blue, Br, SectionHeader, SectionTitle } from 'components';

export default function Hero() {
	return (
		<Box
			sx={{
				pt: { xs: 10, md: 15 },
				pb: { xs: 6, md: 10 },
				background: 'linear-gradient(#2A395F 0%, #1A2237 100%)',
				color: 'white',
			}}
		>
			<Container>
				<Grid container spacing={{ xs: 4, md: 10 }} direction="row-reverse">
					<Grid item xs={12} md={6}>
						<Stack height="100%" justifyContent="center">
							<SectionTitle textAlign="left" pt={0}>
								CASE STUDY
							</SectionTitle>

							<SectionHeader
								textAlign="left"
								color="white"
								fontSize={{ xs: 34, sm: 42, lg: 46 }}
								lineHeight={{ md: '54px', xs: '46px' }}
								mt="8px !important"
							>
								Enhancing Customer <Br sm md={false} />
								Service and Experience <Br xs={false} sm />
								with SavedBy:
								<Blue>
									<Br xs />
									DedCool
								</Blue>
							</SectionHeader>
							<Stack sx={{ alignItems: { xs: 'center', sm: 'flex-start' } }}>
								<img
									src="DedCool.png"
									alt="DedCool Logo"
									style={{
										width: 225,
										objectFit: 'content',
									}}
								/>
							</Stack>
						</Stack>
					</Grid>
					<Grid item xs={12} md={6}>
						<Card
							sx={{
								borderRadius: 10,
								width: '100%',
							}}
						>
							<CardMedia component="img" src="DedCoolProduct1.png" />
						</Card>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
