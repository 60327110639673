import React from 'react';
import Slider from 'react-slick';
import Rating from './Rating';
import {
	SectionHeader,
	SectionText,
	SectionTitle,
	useBreakpoint,
} from 'components';
import { Card, Stack, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const MerchantReviews = () => {
	const bp = useBreakpoint();

	const testimonials = [
		{
			id: 1,
			message:
				'Thank you so much for getting this handled so quickly!! Way better than dealing with USPS!',
			name: '— Jennifer S.',
		},
		{
			id: 2,
			message:
				'Appreciate you taking care of me and getting that sent out again, looking forward to finally getting my product!',
			name: '— Kyle C.',
		},
		{
			id: 3,
			message:
				'That was so quick! Thanks again for your help and taking care of me today!',
			name: '— Veronica Y.',
		},
		{
			id: 4,
			message:
				'Thank you! I received my new shirts just in time for my sons birthday party.',
			name: '— Carol T.',
		},
		{
			id: 5,
			message:
				'That was quick - Tell Caitlyn thanks for all her help. 5 star service!',
			name: '— Donny B.',
		},
	];

	const CustomPrevArrow = (props) => {
		const { onClick } = { ...props };
		return (
			<Stack
				sx={{
					position: 'absolute',
					top: '35%',
					left: bp.isLg ? '-3%' : bp.isMd ? '-2%' : '0%',
					cursor: 'pointer',
					zIndex: 1,
				}}
				onClick={onClick}
			>
				<KeyboardArrowLeft
					sx={{
						fontSize: '4rem',
						color: '#38B6FF',
					}}
				/>
			</Stack>
		);
	};

	const CustomNextArrow = (props) => {
		const { onClick } = { ...props };
		return (
			<Stack
				sx={{
					position: 'absolute',
					top: '35%',
					right: bp.isLg ? '-3%' : bp.isMd ? '-2%' : '0%',
					cursor: 'pointer',
					zIndex: 1,
				}}
				onClick={onClick}
			>
				<KeyboardArrowRight
					sx={{
						fontSize: '4rem',
						color: '#38B6FF',
					}}
				/>
			</Stack>
		);
	};

	/**
	 * @type {import("react-slick").Settings}
	 */
	const settings = {
		arrows: bp.isMd ? true : false,
		dots: true,
		infinite: true,
		speed: 2000,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: true,
		pauseOnDotsHover: true,
		nextArrow: <CustomNextArrow />,
		prevArrow: <CustomPrevArrow />,
		centerMode: true,
		centerPadding: '0',
		responsive: [
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 615,
				settings: {
					slidesToShow: 1.25,
					slidesToScroll: 1,
					infinite: false,
				},
			},
			{
				breakpoint: 750,
				settings: {
					slidesToShow: 1.4,
					slidesToScroll: 1,
					infinite: false,
				},
			},
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 3600,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<Stack sx={{ mb: 5 }}>
			<Stack textAlign="center">
				<SectionTitle>CUSTOMER REVIEWS</SectionTitle>
				<SectionHeader>
					{bp.isMd
						? 'Your customers will love\nSavedBy’s support!'
						: 'Your customers\nwill love SavedBy’s\nsupport!'}
				</SectionHeader>
				<SectionText width={bp.isSm ? '80%' : '100%'}>
					{bp.isSm
						? 'Real reviews from our brands’ customers who love SavedBy shipping support:'
						: 'Real reviews from our brands’ customers who\nlove SavedBy shipping support:'}
				</SectionText>
				<Slider {...settings}>
					{testimonials.map((testimonial) => (
						<Stack
							key={testimonial.id}
							display="flex !important"
							alignItems="center"
							justifyContent="center"
							width="100%"
							sx={{ p: 2 }}
						>
							<Card
								sx={{
									borderRadius: '20px',
									background:
										'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
									justifyContent: 'space-between',
									height: '225px',
									width: bp.isSm ? '325px' : '295px',
									p: 3,
									mb: 2,
								}}
							>
								<Stack
									justifyContent="space-between"
									textAlign="left"
									spacing={2}
								>
									<Rating />
									<Typography>{testimonial.message}</Typography>
									<Typography>{testimonial.name}</Typography>
								</Stack>
							</Card>
						</Stack>
					))}
				</Slider>
			</Stack>
		</Stack>
	);
};

export default MerchantReviews;
