import {
	Box,
	Button,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { LoadingIndicator, useBreakpoint } from '../components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ContactForm = () => {
	const [companyName, setCompanyName] = useState('');
	const [isLoading, setLoading] = useState(false);
	const bp = useBreakpoint();
	const validationSchema = yup.object({
		name: yup.string().required('Name is required'),
		companyName: yup.string().required('Company Name is required'),
		email: yup
			.string()
			.email('Invalid email address')
			.required('Work Email is required'),
		phone: yup
			.string()
			.matches(/^[0-9]+$/, 'Must be only digits')
			.required('Phone Number is required'),
		referral: yup.string().required('Selection is required'),
		referralInformation: yup.string(),
	});

	const formik = useFormik({
		initialValues: {
			name: '',
			companyName: '',
			email: '',
			phone: '',
			referral: '',
			referralInformation: '',
		},
		validationSchema,
		onSubmit: (values) => {
			setLoading(true);
			// Handle form submission here

			fetch(`${process.env.REACT_APP_API_URL}/v1/book-call`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(values),
			})
				.then(() => {
					setCompanyName(values.companyName);
				})
				.catch(console.error)
				.finally(setLoading);
		},
	});

	return (
		<Box display="flex" alignItems="center" height="100%">
			<Box
				width={1}
				display={'flex'}
				justifyContent={'center'}
				alignItems={'center'}
				bgcolor={'white'}
				borderRadius={5}
			>
				{companyName ? (
					<Confirmation
						{...{ companyName, bp, onClose: () => setCompanyName() }}
					/>
				) : (
					<Box maxWidth={800} borderRadius={5}>
						<form>
							<Grid container spacing={2} sx={{ p: 5 }}>
								<Grid item xs={12}>
									<InputLabel htmlFor="name" sx={{ color: '#1A2237' }}>
										Your Name:*
									</InputLabel>
									<TextField
										fullWidth
										sx={{ bgcolor: 'white', borderRadius: 3 }}
										id="name"
										name="name"
										variant="outlined"
										value={formik.values.name}
										onChange={formik.handleChange}
										error={formik.touched.name && Boolean(formik.errors.name)}
										helperText={formik.touched.name && formik.errors.name}
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel htmlFor="companyName" sx={{ color: '#1A2237' }}>
										Company Name:*
									</InputLabel>
									<TextField
										fullWidth
										sx={{ bgcolor: 'white', borderRadius: 3 }}
										id="companyName"
										name="companyName"
										variant="outlined"
										value={formik.values.companyName}
										onChange={formik.handleChange}
										error={
											formik.touched.companyName &&
											Boolean(formik.errors.companyName)
										}
										helperText={
											formik.touched.companyName && formik.errors.companyName
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel htmlFor="workEmail" sx={{ color: '#1A2237' }}>
										Work Email:*
									</InputLabel>
									<TextField
										fullWidth
										type="email"
										sx={{ bgcolor: 'white', borderRadius: 3 }}
										id="email"
										name="email"
										variant="outlined"
										value={formik.values.email}
										onChange={formik.handleChange}
										error={formik.touched.email && Boolean(formik.errors.email)}
										helperText={formik.touched.email && formik.errors.email}
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel htmlFor="phoneNumber" sx={{ color: '#1A2237' }}>
										Phone Number:*
									</InputLabel>
									<TextField
										fullWidth
										sx={{ bgcolor: 'white', borderRadius: 3 }}
										id="phone"
										name="phone"
										variant="outlined"
										value={formik.values.phone}
										onChange={formik.handleChange}
										error={formik.touched.phone && Boolean(formik.errors.phone)}
										helperText={formik.touched.phone && formik.errors.phone}
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel htmlFor="referral" sx={{ color: '#1A2237' }}>
										Where did you hear about us?*
									</InputLabel>
									<TextField
										fullWidth
										select
										sx={{ bgcolor: 'white', borderRadius: 1.5 }}
										id="referral"
										name="referral"
										variant="outlined"
										value={formik.values.referral}
										onChange={formik.handleChange}
										error={
											formik.touched.referral && Boolean(formik.errors.referral)
										}
										helperText={
											formik.touched.referral && formik.errors.referral
										}
									>
										<MenuItem value={'LinkedIn'}>LinkedIn</MenuItem>
										<MenuItem value={'Twitter'}>Twitter</MenuItem>
										<MenuItem value={'Facebook'}>Facebook</MenuItem>
										<MenuItem value={'Email'}>Email</MenuItem>
										<MenuItem value={'Friend'}>Friend</MenuItem>
										<MenuItem value={'Other'}>Other</MenuItem>
									</TextField>
								</Grid>
								{formik.values.referral === 'Other' && (
									<Grid item xs={12}>
										<InputLabel
											htmlFor="referralInformation"
											sx={{ color: '#1A2237' }}
										>
											Please tell us more!
										</InputLabel>
										<TextField
											fullWidth
											multiline
											sx={{ bgcolor: 'white', borderRadius: 1 }}
											id="referralInformation"
											name="referralInformation"
											variant="outlined"
											value={formik.values.referralInformation}
											onChange={formik.handleChange}
										/>
									</Grid>
								)}
								<Grid item xs={12}>
									<Button
										onClick={formik.handleSubmit}
										type="submit"
										variant="contained"
										size="large"
										sx={{
											width: { md: 175 },
											fontWeight: 900,
											borderRadius: '60px', // Set the border radius to 60px
											background:
												'linear-gradient(135deg, #0DB2FA 0%, #0357FF 100%)', // Set the linear gradient background
											// Add any additional styles here
											mt: 2,
										}}
										fullWidth={bp.isMd ? false : true}
									>
										BOOK CALL
									</Button>
									<Typography
										component="p"
										color={'#1A2237'}
										sx={{ fontWeight: 400, mt: 4, fontSize: 12 }}
									>
										By submitting this form, you agree to receive promotional
										messages from SavedBy and agree to the{' '}
										<Link
											to="/privacy-policy"
											style={{ textDecoration: 'none' }}
										>
											Privacy Policy
										</Link>
										.
									</Typography>
								</Grid>
							</Grid>
						</form>
						<LoadingIndicator open={!!isLoading} />
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default ContactForm;

function Confirmation({ companyName, bp, onClose }) {
	return (
		<Box position="relative" width="100%" height="100%">
			<Stack spacing={1} alignItems="center" sx={{ p: bp.isMd ? 10 : 5 }}>
				<IconButton
					onClick={onClose}
					sx={{ position: 'absolute', display: 'block', top: 0, right: 0 }}
				>
					<Close />
				</IconButton>
				<LazyLoadImage src="./partying-face.gif" />
				<Typography textAlign="center">
					<b>Submitted!</b>
					<br />
					We’re excited to learn more about {companyName}.
					<br />
					You’ll be hearing from us shortly!
				</Typography>
			</Stack>
		</Box>
	);
}

Confirmation.propTypes = {
	bp: PropTypes.any,
	companyName: PropTypes.string,
	onClose: PropTypes.func,
};
