import * as React from 'react';
import { Blue, useBreakpoint } from 'components';
import { Avatar, Box, Card, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
	Timeline,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator,
	TimelineContent,
	TimelineConnector,
	TimelineDot,
} from '@mui/lab';
import { Image } from 'mui-image';

const steps = [
	{
		image: (
			<Image
				sx={{ p: { md: 6, xs: 2 } }}
				style={{ height: '100%', objectFit: 'contain' }}
				src={'/Cart.svg'}
			/>
		),
		label: (
			<>
				A customer makes an order on your store and buys{' '}
				<Blue>SavedBy Package Protection.</Blue>
			</>
		),
		description: `Customers are more likely to checkout from a store if they know their package is protected. 75% of customers have abandoned a purchase due to unsatisfactory shipping options in the checkout.
    \nSavedBy makes it quick and easy to increase website conversion rates by offering Package Protection with our SavedBy Widget.`,
	},
	{
		image: (
			<Image
				sx={{ p: 6 }}
				style={{ height: '100%', objectFit: 'contain' }}
				src={'/ChatGraphic.svg'}
			/>
		),
		label: (
			<>
				The Carrier decides they need to <Blue>lose a package</Blue> today - and
				choose your customer.
			</>
		),
		description:
			'Instead of taking on the time and costs associated with package issues, SavedBy turns this cost center into a profit center.\n\nWe will place a brand new order on your store, doubling the revenue for any lost, stolen, or damaged package that you incur.',
	},
	{
		image: <Image src={'/PhoneImage.svg'} />,
		label: (
			<>
				Your customer reaches out to SavedBy and we issue them a refund or
				re-order <Blue>within 24 hours.</Blue>
			</>
		),

		description: `We’ll re-order from your website, or issue a refund to your customer. We'll respond to your customer claims ASAP, sometimes within minutes!`,
	},
];

export default function ProtectStepper() {
	const bp = useBreakpoint();

	return !bp.isMd ? (
		<Stack spacing={4} width="100%" py={8}>
			{steps.map((step, index) => (
				<Stack key={index} width="100%" spacing={2} alignItems="center">
					<Card
						sx={{
							borderRadius: 10,
							width: '100%',
							background: 'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
							aspectRatio: '1',
						}}
					>
						{step.image}
					</Card>

					<Icon index={index} />

					<Typography fontWeight={800} variant="h5">
						{step.label}
					</Typography>

					<Typography sx={{ whiteSpace: 'break-spaces' }}>
						{step.description}
					</Typography>
				</Stack>
			))}
		</Stack>
	) : (
		<Box pb={6} position="relative" display="flex">
			<Timeline width="100%">
				{steps.map((step, index) => (
					<TimelineItem key={index} sx={{ alignItems: 'center' }}>
						<TimelineOppositeContent>
							<Card
								width={500}
								sx={{
									borderRadius: 10,
									aspectRatio: '1',
									background:
										'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
								}}
							>
								{step.image}
							</Card>
						</TimelineOppositeContent>

						<TimelineSeparator>
							<TimelineConnector
								hidden={index === 2}
								sx={{
									position: 'absolute',
									mt: 4,
									height: 560,
									width: 4,
									background: 'linear-gradient(to bottom, #38B6FF, #c2e3ff)',
								}}
							/>
							<TimelineDot
								sx={{
									p: 0,
									boxShadow: 'none',
									borderStyle: 'none',
								}}
							>
								<Icon index={index} />
							</TimelineDot>
						</TimelineSeparator>

						<TimelineContent width="100%">
							<Stack pl={2} spacing={2}>
								<Typography fontWeight={900} variant="h5">
									{step.label}
								</Typography>
								<Typography sx={{ whiteSpace: 'break-spaces' }}>
									{step.description}
								</Typography>
							</Stack>
						</TimelineContent>
					</TimelineItem>
				))}
			</Timeline>
		</Box>
	);
}

function Icon({ index }) {
	return (
		<Avatar
			sx={{
				width: { xs: 80, md: 100 },
				height: { xs: 80, md: 100 },
				fontSize: { xs: 36, md: 50 },
				fontWeight: 700,
				bgcolor: '#38B6FF',
				borderStyle: 'solid',
				borderColor: '#d7f0ff',
				borderWidth: { xs: 8, md: 12 },
			}}
		>
			{index + 1}
		</Avatar>
	);
}

Icon.propTypes = {
	index: PropTypes.number,
};

// h5.steps-title {
// 	color: #1a2237;
// 	text-shadow: 4px 4px 7px #826c6c66;
// 	font-size: 30px;
// 	font-style: normal;
// 	font-weight: 600;
// 	line-height: 120%; /* 38.4px */
// }

// .css-1ilpik-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
// 	font-size: 50px;
// 	outline: 8px solid #c2e3ff;
// 	border-radius: 90px;
// 	margin-left: -12px;
// 	margin-right: 12px;
// 	z-index: 2;
// }

// .MuiStepConnector-line {
// 	display: block;
// 	border-left: 3px solid #eaeaea !important;
// 	height: 40px;
// 	margin-left: 12px;
// 	position: absolute;
// 	top: 7%;
// 	left: 0;
// 	height: 81%;
// 	z-index: 1;
// 	border-image: linear-gradient(to bottom, #377dff, #c2e3ff, #377dff, #c2e3ff) 1;
// }
