import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Container from 'components/Container';
import { Content } from './components';

export default function ShopifyAppPrivacyPolicyView() {
	const theme = useTheme();

	return (
		<Container>
			<Box boxShadow={4} borderRadius={2} pt={4}>
				<Box bgcolor={theme.palette.primary.main} borderRadius={2}>
					<Container paddingX={{ xs: 2, sm: 4 }}>
						<Typography
							variant={'h4'}
							gutterBottom
							sx={{
								fontWeight: 700,
								color: theme.palette.common.white,
							}}
						>
							SavedBy Package Protection Policies:
						</Typography>
						<Typography
							gutterBottom
							sx={{
								color: theme.palette.common.white,
							}}
						>
							Last modified on <strong>8 Feb, 2023</strong>
						</Typography>
					</Container>
					<Box
						component={'svg'}
						preserveAspectRatio="none"
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						viewBox="0 0 1920 100.1"
						width={1}
						marginBottom={-1}
					>
						<path
							fill={theme.palette.background.paper}
							d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
						></path>
					</Box>
				</Box>
				<Container
					paddingTop={'0 !important'}
					paddingX={{ xs: 2, sm: 4 }}
					position={'relative'}
					top={0}
				>
					<Box spacing={4} flexDirection={{ xs: 'column-reverse', md: 'row' }}>
						<Content />
					</Box>
				</Container>
			</Box>
		</Container>
	);
}
