import { Box, Typography, Grid } from '@mui/material';
import React from 'react';
import { Blue } from 'components';

export const ShippingIssuesLogos = () => {
	const logos = [
		{ src: '/Theft.svg', text: 'Theft' },
		{ src: '/Damaged.svg', text: 'Damaged Items' },
		{ src: '/Lost.svg', text: 'Lost' },
		{ src: '/Return.svg', text: 'Return to Sender' },
		{ src: '/Beyond.svg', text: '& Beyond' },
	];

	return (
		<Box textAlign="center" sx={{ my: { xs: 4, md: 8 } }}>
			<Typography variant="h4" fontWeight={600} gutterBottom>
				We cover <Blue>all major shipping issues</Blue> for brands A-Z
			</Typography>

			<Grid
				container
				flexWrap="wrap"
				justifyContent={{ xs: 'center', sm: 'space-evenly' }}
			>
				{logos.map((logo, index) => {
					return (
						<Grid
							item
							xs={4}
							md
							key={index}
							width={110}
							alignItems="center"
							justifyContent="center"
							sx={{ mt: 4 }}
						>
							<img src={logo.src} width={100} height={100} />
							<Typography
								noWrap
								variant="h5"
								pt={1}
								fontSize={{ xs: 14, md: 12 }}
							>
								{logo.text}
							</Typography>
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
};
