import React from 'react';
import { Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { Blue, Br, useBreakpoint } from 'components';
import PropTypes from 'prop-types';

export default function DataSection() {
	const bp = useBreakpoint();
	return (
		<Container sx={{ py: 8 }}>
			<Grid container spacing={8}>
				<Grid item xs={12} md={6}>
					<Stack spacing={{ xs: 5, md: 10 }}>
						<Data
							title="Decrease in Customer Service Time Spent on Package Issues"
							number={'100%'}
						/>
						<Data
							title="Reduction in Costs Related to Shipping Mishaps"
							number={'100%'}
						/>
						<Data title="Claim Approval Rate" number={'99%'} />
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack spacing={6}>
						<Typography variant={bp.isSm ? 'h4' : 'h3'} fontWeight={900}>
							Pack Leashes Saw a<Br sm={false} md lg={false} />{' '}
							<Blue px={0}>
								100% Reduction in Costs Related to Shipping Mishaps
							</Blue>{' '}
							<Br xs sm={false} lg />
							with SavedBy Package Protection.
						</Typography>
						<Typography mt="36px !important">
							Pack Leashes is an e-commerce pet brand focusing on delivering
							durable products that are inspired by adventure and built to
							handle the elements. As a small business, the Pack team focuses on
							building meaningful customer experiences and sustainable growth.
							That’s where SavedBy comes in.
						</Typography>

						<Typography
							variant={bp.isMd ? 'h5' : 'h3'}
							gutterBottom
							fontWeight={800}
							mt="36px !important"
						>
							The Problem:
						</Typography>
						<Typography mt="20px !important">
							When businesses scale, so do logistical headaches. As Pack Leashes
							increased their year-over-year growth, customers were experiencing
							more lost, damaged, and stolen packages. At volume, the Pack team
							was facing hundreds of customer service nightmares every week, and
							losing revenue for orders that needed to be replaced or refunded.
						</Typography>

						<Typography
							variant={bp.isMd ? 'h5' : 'h3'}
							gutterBottom
							fontWeight={800}
							mt="36px !important"
						>
							The Solution:
						</Typography>
						<Typography mt="20px !important">
							Pack Leashes teamed up with SavedBy to offer Package Protection.
							Effectively, the company was able to turn a cost center into a
							profit center - all on top of increasing their conversion rate and
							streamlining CX.
							<Br xs />
							<Br xs />
							In February of 2023, Pack Leashes brought on SavedBy Package
							Protection. They cut customer service times by 93% and recovered
							100% of lost revenues since then. For every delivery gone wrong,
							SavedBy covered the cost and saved the headache.
						</Typography>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	);
}

function Data({ title, number }) {
	return (
		<Stack>
			<Typography variant="h5" fontWeight={800}>
				{title}
				<Divider sx={{ borderColor: 'black', pt: 1 }} />
			</Typography>
			<Blue fontSize={{ xs: 54, md: 75 }} fontWeight={800}>
				{number}
			</Blue>
			<Typography fontSize={18} variant="caption">
				with SavedBy Package Protection
			</Typography>
		</Stack>
	);
}
Data.propTypes = {
	title: PropTypes.string,
	number: PropTypes.string,
};
