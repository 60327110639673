import React from 'react';
import Container from 'components/Container';
import Box from '@mui/material/Box';
import BuildCustomerLoyalty from './components/BuildCustomerLoyalty/BuildCustomerLoyalty';
import FastResolutions from './components/FastResolutions/Mobile/FastResolutions';
import SavedByHasYouCovered from './components/SavedByHasYouCovered';
import SuccessStories from './components/SuccessStories/SuccessStories';
import MerchantReviews from './components/MerchantReviews/MerchantReviews';
import IntegrationConnect from './components/IntegrationConnect/IntegrationConnect';
import CustomerReviews from './components/CustomerReviews/CustomerReviews';
import { CoverageShippingIssues } from './components/CoverageShippingIssues/CoverageShippingIssues';
import { Hero, LogoSection, ShippingIssuesLogos } from './components';

const LandingPage = () => {
	return (
		<>
			<Box
				sx={{
					overflow: 'hidden',
					background: 'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
					marginBottom: '-40px',
					zIndex: 1,
					position: 'relative',
					// Add any additional styles here
				}}
			>
				<Container
					sx={{
						paddingBottom: '0px !important',
					}}
				>
					<Hero />
				</Container>
			</Box>

			<Box bgcolor={'#F7FAFF'} sx={{ zIndex: 2, position: 'relative' }}>
				<Container sx={{ p: '0px !important' }}>
					<LogoSection />
				</Container>
			</Box>

			<Box>
				<Container sx={{ p: '0px !important' }}>
					<ShippingIssuesLogos />
				</Container>
			</Box>

			<Box
				sx={{ background: 'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)' }}
			>
				<Container sx={{ p: '0px !important' }}>
					<BuildCustomerLoyalty />
				</Container>
			</Box>

			<Box>
				<Container sx={{ p: '0px !important' }}>
					<FastResolutions />
				</Container>
			</Box>

			<Box
				sx={{
					background: 'linear-gradient(180deg, #30374B 0%, #1A2237 100%)',
					backgroundRepeat: 'repeat-x',
					position: 'relative',
				}}
			>
				<Container sx={{ pt: '32px !important' }}>
					<SavedByHasYouCovered />
				</Container>
			</Box>

			<Box>
				<Container>
					<SuccessStories />
				</Container>
			</Box>

			<Box>
				<Container sx={{ pt: '0px !important' }}>
					<MerchantReviews />
				</Container>
			</Box>

			<Box
				sx={{
					background: 'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
					px: 2,
					py: 0,
				}}
			>
				<Container sx={{ pt: '0px !important' }}>
					<IntegrationConnect />
				</Container>
			</Box>

			<Box>
				<Container sx={{ pt: '16px !important' }}>
					<CustomerReviews />
				</Container>
			</Box>

			<Box
				sx={{
					background: 'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
					padding: '20px', // Add padding as needed
				}}
			>
				<CoverageShippingIssues />
			</Box>
		</>
	);
};

export default LandingPage;
