import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function SectionHeader({ children, color, ...rest }) {
	return (
		<Typography
			color={color || '#1A2237'}
			textAlign="center"
			fontWeight={800}
			fontSize={{ md: 66, xs: 36 }}
			lineHeight={{ md: '68px', sm: '54px', xs: '40px' }}
			variant="h2"
			mt={{ md: 2, xs: 2 }}
			mb={{ md: 0, xs: 1 }}
			whiteSpace="break-spaces"
			{...rest}
		>
			{children}
		</Typography>
	);
}

SectionHeader.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.string.isRequired]),
	color: PropTypes.string,
	rest: PropTypes.object,
};
