import * as React from 'react';
import { Blue, useBreakpoint } from 'components';
import { Avatar, Box, Card, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
	Timeline,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator,
	TimelineContent,
	TimelineConnector,
	TimelineDot,
} from '@mui/lab';
import { Image } from 'mui-image';

const steps = [
	{
		image: (
			<Image
				sx={{ p: { md: 6, xs: 2 } }}
				style={{ height: '100%', objectFit: 'contain' }}
				src={'/Cart.svg'}
			/>
		),
		label: (
			<>
				Add <Blue>SavedBy Package Protection</Blue> whenever you see it at
				checkout!
			</>
		),
		description: `If a package issue arises (Lost, Stolen, Damaged, Returned to Sender) then SavedBy will make sure that you are sent a replacement ASAP. \n\nNot sure if the store will offer a replacement if your package doesn't show up? Not confident that USPS delivers packages with 100% accuracy? Get SavedBy and rest easy - you're covered.`,
	},
	{
		image: (
			<Image
				sx={{ p: 6 }}
				style={{ height: '100%', objectFit: 'contain' }}
				src={'/ChatGraphic.svg'}
			/>
		),
		label: (
			<>
				Carrier decides they need to <Blue> lose a package</Blue> today - and
				they choose to lose yours.
			</>
		),
		description: `Wait on hold with your carrier for 6+ hours just for them to tell you they have no idea where your package is? No thanks.\n\nReach out to SavedBy and we will send out a new package to you, Same Day. No hold times, no hassle. Just great customer service and quick resolutions.`,
	},
	{
		image: <Image src={'/PhoneImage.svg'} />,
		label: (
			<>
				Reach out and SavedBy will issue you a refund or re-order{' '}
				<Blue>within 24 hours.</Blue>
			</>
		),
		description: `We'll place a brand new order on your behalf to make sure that you get the items that you ordered.\n\nShort on time? No problem - we can resolve your claim the same day that you reach out to us.`,
	},
];

export default function ProtectStepper() {
	const bp = useBreakpoint();

	return !bp.isMd ? (
		<Stack spacing={4} width="100%" py={6}>
			{steps.map((step, index) => (
				<Stack key={index} width="100%" spacing={3} alignItems="center">
					<Card
						sx={{
							borderRadius: 10,
							width: '100%',
							background: 'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
							aspectRatio: '1',
						}}
					>
						{step.image}
					</Card>

					<Icon index={index} />

					<Typography fontWeight={800} variant="h5">
						{step.label}
					</Typography>

					<Typography sx={{ whiteSpace: 'break-spaces' }}>
						{step.description}
					</Typography>
				</Stack>
			))}
		</Stack>
	) : (
		<Box pb={4} position="relative" display="flex">
			<Timeline width="100%">
				{steps.map((step, index) => (
					<TimelineItem key={index} sx={{ alignItems: 'center', my: 1 }}>
						<TimelineOppositeContent>
							<Card
								width={500}
								sx={{
									borderRadius: 10,
									aspectRatio: '1',
									background:
										'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
								}}
							>
								{step.image}
							</Card>
						</TimelineOppositeContent>

						<TimelineSeparator>
							<TimelineConnector
								hidden={index === 2}
								sx={{
									position: 'absolute',
									mt: 4,
									height: 560,
									width: 4,
									background: 'linear-gradient(to bottom, #38B6FF, #c2e3ff)',
								}}
							/>
							<TimelineDot
								sx={{
									p: 0,
									boxShadow: 'none',
									borderStyle: 'none',
								}}
							>
								<Icon index={index} />
							</TimelineDot>
						</TimelineSeparator>

						<TimelineContent width="100%">
							<Stack pl={2} spacing={2}>
								<Typography fontWeight={900} variant="h5">
									{step.label}
								</Typography>
								<Typography sx={{ whiteSpace: 'break-spaces' }}>
									{step.description}
								</Typography>
							</Stack>
						</TimelineContent>
					</TimelineItem>
				))}
			</Timeline>
		</Box>
	);
}

function Icon({ index }) {
	return (
		<Avatar
			sx={{
				width: { xs: 80, md: 100 },
				height: { xs: 80, md: 100 },
				fontSize: { xs: 36, md: 50 },
				fontWeight: 700,
				bgcolor: '#38B6FF',
				borderStyle: 'solid',
				borderColor: '#d7f0ff',
				borderWidth: { xs: 8, md: 12 },
			}}
		>
			{index + 1}
		</Avatar>
	);
}

Icon.propTypes = {
	index: PropTypes.number,
};
