import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Image } from 'mui-image';
import { useNavigate } from 'react-router-dom';

const caseStudies = [
	{
		title: 'Pack Leashes',
		image: '/Matching_Collection_400.svg',
		logo: '/PackLeashes.svg',
		href: '/pack-case-study',
	},
	{
		title: 'Axe & Sledge',
		image: '/ScreenShot.svg',
		logo: '/AxeSledge.svg',
		href: '/axe-case-study',
	},
];

const SuccessStories = () => {
	const nav = useNavigate();

	return (
		<Box>
			<Stack>
				<Typography textAlign="center" fontWeight={700} variant="h2">
					Success Stories
				</Typography>
				<Typography
					textAlign="center"
					fontWeight={300}
					marginTop={0}
					marginBottom={3}
					fontSize={{ md: 20 }}
				>
					SavedBy Package Protection is trusted by brands all over the world.
				</Typography>
				<Stack
					direction={{ xs: 'column', md: 'row' }}
					spacing={4}
					alignItems="center"
					width="100%"
					justifyContent="center"
				>
					{caseStudies.map((cs, key) => (
						<Stack
							key={key}
							alignItems="center"
							spacing={2}
							onClick={() => nav(cs.href)}
							sx={{ cursor: 'pointer' }}
						>
							<Image src={cs.image} />
							<Typography variant="h4" fontWeight={800}>
								{cs.title}
							</Typography>
							<Box
								sx={{
									position: 'absolute',
									top: '40%',
									left: '22%',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<LazyLoadImage src={cs.logo} width={250} />
							</Box>
						</Stack>
					))}
				</Stack>
			</Stack>
		</Box>
	);
};

export default SuccessStories;
