import { React, useState } from 'react';
import { Box, Typography, Link, Container, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const contentData = [
	{
		id: 1,
		name: 'Stolen Items and Packages Policy',
		Info: <StolenContent />,
	},
	{
		id: 2,
		name: 'Lost Items Policy',
		Info: <LostContent />,
	},
	{
		id: 3,
		name: 'Damaged Items and Packages Policy',
		Info: <DamagedContent />,
	},
	{
		id: 4,
		name: 'Filing a Claim',
		Info: <FileClaimContent />,
	},
];
const Content = () => {
	const [open, setOpen] = useState({});

	const handleOpen = (id) => {
		setOpen((open) => ({ ...open, [id]: !open[id] }));
	};

	return (
		<Container maxWidth="md">
			<Typography
				variant={'h3'}
				gutterBottom
				display="block"
				style={{ whiteSpace: 'pre-line', fontSize: 13 }}
			>
				<Typography
					sx={{
						fontWeight: 'bold',
						fontSize: 13,
					}}
					display="inline"
				>
					Are you looking to file a claim?:
				</Typography>{' '}
				Please review the policies below to ensure you are within the
				requirements. You can file a claim either by sending us an email to{' '}
				<Box component={Link} href="mailto:claims@savedby.io">
					claims@savedby.io
				</Box>{' '}
				or by filing a claim directly on our site here:{' '}
				<Box component={Link} href="/file-a-claim">
					File A Claim
				</Box>
				. See the bottom of the page for the required template when emailing us.
				<br></br>
				<br></br>
				<Typography
					display="inline-block"
					sx={{ fontWeight: 'bold', fontSize: 13 }}
				>
					SavedBy Package Protection™
				</Typography>{' '}
				covers packages that are lost, damaged, or stolen
				<sup style={{ verticalAlign: 'super' }}>1</sup>. Please review our
				policies below to determine if your package is covered by SavedBy.
				<br></br>
				<br></br>
				<Typography
					display="inline-block"
					sx={{ fontStyle: 'italic', fontSize: 13 }}
				>
					Note:
				</Typography>{' '}
				SavedBy covers the cost of the items in an order. SavedBy explicitly
				does not cover any other fees or charges. These include, but are not
				limited to, shipping costs, customs and duties, taxes, the SavedBy
				protection cost, other costs incurred by the customer.<br></br>
				<br></br>
			</Typography>

			{contentData.map((policy, i) => (
				<Box key={i} sx={{ p: 1 }}>
					<Box
						display="flex"
						sx={{ justifyContent: 'space-between', alignItems: 'center' }}
						onClick={() => handleOpen(policy.id)}
					>
						<Typography
							variant={'h4'}
							gutterBottom
							display="block"
							sx={{
								fontWeight: 'medium',
							}}
						>
							{policy.name}
						</Typography>
						{open[policy.id] ? <ExpandLess /> : <ExpandMore />}
					</Box>

					<Collapse in={open[policy.id]} timeout="auto" unmountOnExit>
						{policy.Info}
					</Collapse>
				</Box>
			))}
		</Container>
	);
};

export default Content;

function StolenContent() {
	return (
		<Box
			sx={{
				fontWeight: 'medium',
				whiteSpace: 'pre-line',
				fontSize: 12,
			}}
		>
			SavedBy, at our sole discretion, may require a police report when the
			customer’s package is marked as &quot;delivered&quot;, but has not been
			received by the customer. See the requirements below:
			<br></br>
			<Typography sx={{ p: 2, fontSize: 12 }}>
				1. The customer may be required to file a police report for orders that
				are $600 or more<sup style={{ verticalAlign: 'super' }}>2</sup>. Include
				an explanation within the police report that SavedBy Package Protection
				is the company that has protected the package, and is helping in the
				dispute and resolution process for the customer and the merchant.{' '}
				<br></br>
				<br></br> 2. The customer must send SavedBy the police report, via email
				to{' '}
				<Box component={Link} href="mailto:claims@savedby.io">
					claims@savedby.io
				</Box>
				. Within this email, the customer must include their order #, the
				merchant they purchased from, and the police report itself.
			</Typography>
			SavedBy Package Protection reserves the right to require additional
			information to be submitted if SavedBy determines it is necessary to
			review this additional information for the claim resolution process.
			<br></br>
			<br></br> Note: Orders that are marked through their tracking information
			as “delivered”, but that are not received by the customer, may be required
			to wait 7 days after the delivery date to make a claim, but must file the
			claim within a 16 day period of the delivery date for the claim to be
			valid. <br></br>
			<br></br>
			<Typography sx={{ fontWeight: 'bold' }}>Incorrect Address:</Typography>
			<br></br>If the customer inputs the incorrect address information when
			purchasing through their merchant, SavedBy Package Protection reserves the
			right to not cover the package. If the customer has input the correct
			address information when purchasing through the merchant, but the package
			was delivered to the incorrect address, SavedBy Package Protection will
			cover this as long as there is proof of delivery to the incorrect address.
			This burden of proof falls upon the customer to provide.
			<br></br>
			<br></br>
		</Box>
	);
}

function LostContent() {
	return (
		<Box
			sx={{
				fontWeight: 'medium',
				whiteSpace: 'pre-line',
				fontSize: 12,
			}}
		>
			<Typography sx={{ fontWeight: 'bold' }}>
				Packages presumed to be lost:
			</Typography>
			<br></br>Packages are presumed to be lost for US Domestic orders when the
			status of the package is not &quot;delivered&quot;, and it has been
			greater than 7 days since the last status update per the tracking
			information. <br></br>
			<Typography sx={{ p: 2, fontSize: 12 }}>
				In some cases, SavedBy can presume the package to be lost when it has
				been 7 days or less since the last status update, but no warranties are
				made by SavedBy that they will do so. This can be done on a case-by-case
				basis.
				<br></br>
				<br></br>
				If the tracking information states the package is &quot;arriving
				late&quot; or &quot;delayed delivery&quot;. SavedBy reserves the right
				to not presume the package to be lost until it has been greater than 14
				days since the last status update.
			</Typography>
			Packages are presumed to be lost for International orders when the status
			of the package is not &quot;delivered&quot;, and it has been greater than
			15 days since the last status update per the tracking information.
			<br></br>
			<br></br>All Domestic claims related to lost packages must be filed within
			60 days from the last status update per the tracking information.
			<br></br>
			<br></br> All International claims related to lost packages must be filed
			within 60 days from the last status update per the tracking information.
			<br></br>
			<br></br>
			<Typography sx={{ fontWeight: 'bold' }}>
				Invalid Address or other Delivery Issues:
			</Typography>
			<br></br>Carriers may not be able to successfully deliver a package due to
			reasons such as an invalid address, not being able to find an individual
			to sign for a package, or other delivery issues. SavedBy Package
			Protection does not cover instances where there are invalid addresses or
			other delivery issues arise. The package is not considered, lost, stolen
			or damaged, and the customer will be referred to the merchant to resolve
			the delivery issues. <br></br>
			<br></br>
			<Typography sx={{ fontWeight: 'bold' }}>
				Partial Order Delivery:
			</Typography>
			<br></br>If the customer receives the package, but not all of the ordered
			items are included in the package, and the package does not show proof of
			tampering, then SavedBy reserves the right to not cover the package. The
			customer may be referred to the merchant. <br></br>
			<br></br>
			<Typography sx={{ fontWeight: 'bold' }}>
				Packages that are Held in Customs:
			</Typography>
			<br></br> SavedBy reserves the right to not cover packages that have been
			held in customs, for any reason. The customer may be referred to the
			merchant to resolve the issues of why Customs has held the package.
			<br></br>
			<br></br>SavedBy will not cover customs fees for orders that are replaced
			by SavedBy. This is the responsibility of the customer.
			<br></br>
			<br></br>
		</Box>
	);
}

function DamagedContent() {
	return (
		<Box
			sx={{
				fontWeight: 'medium',
				whiteSpace: 'pre-line',
				fontSize: 12,
			}}
		>
			<Typography sx={{ fontWeight: 'bold' }}>Damaged items:</Typography>
			<br></br> SavedBy Package Protection will cover items that are damaged due
			to the shipment carrier’s neglect. These damages include functional damage
			that stop the product from working as advertised and render it unusable.
			<br></br>
			<br></br> SavedBy Package Protection will not cover cosmetic damages,
			manufacturing damages, damages as a result of the merchant’s negligence
			regarding avoidable damages as a result of the packaging and shipping of a
			product. SavedBy reserves the right to offer a partial refund on an item
			if it is determined the damage does not render the item unusable. This
			partial refund may range from 1% to 99% of the product value.
			<br></br>
			<br></br> The customer must send SavedBy the evidence of the damage, via
			email to{' '}
			<Box component={Link} href="mailto:claims@savedby.io">
				claims@savedby.io
			</Box>
			. Within this email, the customer must include their order #, the merchant
			they purchased from, and the proof of damage itself. <br></br>
			<br></br>All claims related to Damaged Items must be filed within 10 days.
			<br></br>
			<br></br>
			<Typography sx={{ fontWeight: 'bold' }}>
				Return To Sender (RTS) Policies:
			</Typography>
			<br></br>SavedBy Package Protection will cover packages that are sent back
			to the sender, and marked as “Returned to Sender” by the shipping company.
			<br></br>
			<br></br>All claims related to RTS packages must be made after the package
			has been received by the sender, as confirmed by tracking information, and
			within 10 days of the shipping company marking the package successfully
			returned to sender.<br></br>
			<br></br>
			<Typography sx={{ fontWeight: 'bold' }}>Subscriptions:</Typography>
			<br></br>SavedBy Package Protection can cover items purchased through a
			subscription. The same policies apply. SavedBy will only cover the single
			item within the subscription, not the whole subscription. The customer
			must separately purchase shipping protections for each shipment in a
			subscription.<br></br>
			<br></br>
			<Typography sx={{ fontWeight: 'bold' }}>Custom Items:</Typography>
			<br></br>SavedBy Package Protection cannot reorder custom products from a
			merchant. SavedBy reserves the right to offer a refund, store credit, or
			other acceptable remedy, as determined by SavedBy, for the custom products
			within an order if it is within policy.<br></br>
			<br></br>
			<Typography sx={{ fontWeight: 'bold' }}>Out of Stock Items:</Typography>
			<br></br>
			SavedBy Package Protection cannot cover items that are Out of Stock from
			the merchant. SavedBy will direct the customer to the merchant to resolve
			this issue.<br></br>
			<br></br>
			<Typography sx={{ fontWeight: 'bold' }}>
				Warranties on Replacement Packages:
			</Typography>
			<br></br> SavedBy Package Protection does not cover items or packages that
			are sent as a result of the SavedBy Package Protection coverage.
			Therefore, only the first order with SavedBy Package Protection is covered
			by our policies, any replacement packages, or refunds, are not covered
			under this protection.<br></br>
			<br></br> *1 - As long as these claims are within our policies. SavedBy
			makes no guarantee your package will be protected by our dispute
			resolution process.<br></br>
			<br></br> *2 - SavedBy reserves the right to deny a claim if there is no
			associated police report. <br></br>
			<br></br>For claims, please complete the required Claims Template below
			and email us at{' '}
			<Box component={Link} href="mailto:claims@savedby.io">
				claims@savedby.io
			</Box>{' '}
			or go{' '}
			<Box component={Link} href="/file-a-claim">
				Here
			</Box>{' '}
			to File a Claim.
			<br></br>
			<br></br>
		</Box>
	);
}

function FileClaimContent() {
	return (
		<Box
			sx={{
				fontWeight: 'medium',
				whiteSpace: 'pre-line',
				fontSize: 12,
			}}
		>
			To file a claim, send us an email OR file directly on our website{' '}
			<Box component={Link} href="/file-a-claim">
				Here
			</Box>
			. SavedBy requires that you fill out this questionnaire and include this
			in your email. Please{' '}
			<Typography display="inline" sx={{ fontWeight: 'bold', fontSize: 13 }}>
				COPY / PASTE
			</Typography>{' '}
			this into your claim email. We will ask for you to fill out this template
			if you email us without including this. <br></br>
			<br></br>My Name:<br></br>
			<br></br> Name on Order:<br></br>
			<br></br> Merchant Where Purchase Was Made and Order #: <br></br>
			<br></br>This Claim relates to: (Stolen Package / Damaged Package / Lost
			Package) (SELECT ONLY 1) <br></br>
			<br></br>Detailed Information Regarding the Situation and Why this is
			Eligible for SavedBy Package Protection:
		</Box>
	);
}
