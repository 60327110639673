import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'components/LoadingIndicator';
import { Stack, Grid, Typography } from '@mui/material';

const Content = () => {
	const [terms, setTerms] = useState({});
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + '/v1/cms/terms-of-service')
			.then((r) => r.json())
			.then(({ tos }) => setTerms(tos))
			.catch(console.error)
			.finally(() => setLoading(false));
	}, []);

	return (
		<Stack>
			<Grid paddingTop={{ xs: 4, sm: 4, md: 3, lg: 2, xl: 2 }}>
				<Typography
					sx={{
						textTransform: 'uppercase',
						fontWeight: 'bold',
						fontSize: '24px',
						p: 1,
					}}
					gutterBottom
					color={'textSecondary'}
					align={'center'}
				>
					TERMS AND CONDITIONS
				</Typography>
				<Grid>
					<Typography color="text.secondary" textAlign={'center'}>
						Updated on {new Date(terms.createdAt).toLocaleString()}
					</Typography>

					<Typography
						color="text.secondary"
						sx={{ pt: 3 }}
						style={{ whiteSpace: 'pre-line' }}
					>
						{terms.content}
					</Typography>
				</Grid>
			</Grid>
			<LoadingIndicator open={isLoading} />
		</Stack>
	);
};

Content.propTypes = {
	term: PropTypes.any,
};

export default Content;
