/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
const { REACT_APP_API_URL } = process.env;

import {
	Card,
	Container,
	Divider,
	Fade,
	Stack,
	Typography,
} from '@mui/material';

import { useSearchParams } from 'react-router-dom';
import { withErrorBoundary } from '@sentry/react';
import {
	ErrorView,
	LoadingView,
	LogoContainer,
	OrderInfo,
	PoweredBy,
	ProductUpsell,
	TrackingHistory,
	TrackingStatus,
} from './components';

const style = {
	card: {
		bgcolor: 'white',
		textColor: 'black',
		dividerColor: 'gray',
	},
	page: {
		bgcolor: false,
	},
	logoCard: {
		bgcolor: 'black',
	},
};

const TrackView = () => {
	const [data, setData] = useState();
	const [sp] = useSearchParams();
	const oid = sp.get('oid');
	const mid = sp.get('mid');
	const [error, setError] = useState(false);

	useEffect(() => {
		fetch(`${REACT_APP_API_URL}/v1/tracking?oid=${oid}&mid=${mid}`)
			.then((r) => r.json())
			.then((data) => {
				setData(data);
			})
			.catch(() => setError(true));
	}, [oid, mid]);

	return (
		<Box
			sx={{
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '100vh',
				py: { xs: 2, md: 6 },
				display: {
					xs: 'block',
					md: 'flex',
				},
			}}
		>
			<Container maxWidth="sm">
				{error ? (
					<ErrorView />
				) : !data ? (
					<LoadingView />
				) : (
					<Fade in={!!data} timeout={1500}>
						<Card
							sx={{ bgcolor: style.card.bgcolor, color: style.card.textColor }}
						>
							<Stack>
								<LogoContainer mid={data.merchantData._id} />
								<Divider sx={{ bgcolor: style.card.dividerColor }} />

								{data.trackingData ? (
									<>
										<TrackingStatus {...data.trackingData} />
										<Divider sx={{ bgcolor: style.card.dividerColor }} />

										<TrackingHistory {...data.trackingData} />
									</>
								) : (
									<Typography>No Tracking Data Yet!</Typography>
								)}
								<OrderInfo
									trackingNumber={data.trackingData.tracking_number}
									{...data.orderData}
								/>
								<ProductUpsell upsellConfig={data.merchantData?.trackingPage} />
								<PoweredBy />
							</Stack>
						</Card>
					</Fade>
				)}
			</Container>
		</Box>
	);
};

export default withErrorBoundary(TrackView, { fallback: ErrorView });
