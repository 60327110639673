import React from 'react';
import {
	Box,
	Card,
	CardMedia,
	Container,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import { SectionHeader, SectionTitle } from 'components';
import PropTypes from 'prop-types';

export default function KeyMetrics() {
	return (
		<Box
			sx={{
				py: 8,
				background: 'linear-gradient(#F7FAFF 0%, #E3EDFF 100%)',
				textAlign: 'center',
			}}
		>
			<Container>
				<SectionTitle pt={0}>THE SAVEDBY SOLUTION</SectionTitle>
				<SectionHeader sx={{ pb: { xs: 2, md: 4 } }}>Key Metrics</SectionHeader>

				<Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
					<Grid container rowSpacing={2} columnSpacing={{ xs: 0, md: 2 }}>
						<Grid item xs={12} md={6}>
							<Card sx={{ py: 4 }}>
								<CardMedia component="img" src="PackKeyMetric1.svg" />
							</Card>
						</Grid>
						<Grid item xs={12} md={6}>
							<Card sx={{ py: 4 }}>
								<CardMedia component="img" src="PackKeyMetric2.svg" />
							</Card>
						</Grid>
						<Grid item xs={12} md={6}>
							<Card sx={{ py: 4 }}>
								<CardMedia component="img" src="PackKeyMetric3.svg" />
							</Card>
						</Grid>
						<Grid item xs={12} md={6}>
							<Card sx={{ py: 4 }}>
								<CardMedia component="img" src="PackKeyMetric4.svg" />
							</Card>
						</Grid>
					</Grid>
				</Stack>
			</Container>
		</Box>
	);
}

function Bubble({ children, diameter = 132 }) {
	return (
		<Box
			sx={{
				bgcolor: 'blue',
				borderRadius: '50%',
				width: diameter + 40,
				aspectRatio: '1',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderColor: '#c5eaff',
				borderWidth: 20,
				borderStyle: 'solid',
			}}
		>
			<Typography variant="h3" color="white">
				{children}
			</Typography>
		</Box>
	);
}
Bubble.propTypes = {
	children: PropTypes.string,
	diameter: PropTypes.number,
};
