import React from 'react';
import { Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { Blue, Br, useBreakpoint } from 'components';
import PropTypes from 'prop-types';

export default function DataSection() {
	const bp = useBreakpoint();
	return (
		<Container sx={{ py: 8 }}>
			<Grid container spacing={8}>
				<Grid item xs={12} md={6}>
					<Stack spacing={{ xs: 5, md: 10 }}>
						<Data title="Annual Revenue Increase" number={'$550k'} />
						<Data
							title="Overall Conversion Rate (CVR) Increase"
							number={'+9%'}
						/>
						<Data title="Claim Approval Rate" number={'99%'} />
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack spacing={6}>
						<Typography variant={bp.isSm ? 'h4' : 'h3'} fontWeight={900}>
							Axe & Sledge Supplements Saw
							<Br sm={false} lg /> an Annual Revenue
							<Blue
								pl={0}
								display={bp.isSm ? 'inline' : 'flex'}
								flexWrap="nowrap"
							>
								<Br md lg={false} /> Increase of $550K
							</Blue>{' '}
							with SavedBy Package Protection.
						</Typography>
						<Typography mt="36px !important">
							Axe & Sledge Supplements is an industry-leader in the sports
							nutrition space, who focuses on scientifically-proven supplements
							for bodybuilding, power-lifting, and gym-going community.
							<Br xs />
							<Br xs />
							They are a direct-to-consumer brand that leans heavily on online
							sales.
						</Typography>

						<Typography
							variant={bp.isMd ? 'h5' : 'h3'}
							gutterBottom
							fontWeight={800}
							mt="32px !important"
						>
							The Problem:
						</Typography>
						<Typography mt="16px !important">
							Package issues were a cost center both because of new shipments
							Axe & Sledge needed to send, plus the valuable time taken away
							from the customer service team.
						</Typography>

						<Typography
							variant={bp.isMd ? 'h5' : 'h3'}
							gutterBottom
							fontWeight={800}
							mt="32px !important"
						>
							The Solution:
						</Typography>
						<Typography mt="16px !important">
							Axe & Sledge teamed up with SavedBy to offer Package Protection.
							Effectively, the company was able to turn a cost center into a
							profit center - all on top of increasing their conversion rate and
							streamlining CX.
							<br />
							<br />
							Why the conversion rate increase? It&apos;s simple- customers feel
							more confident in their purchase knowing it&apos;s protected.
							Shouldn&apos;t every purchase be made in confidence?
						</Typography>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	);
}

function Data({ title, number }) {
	return (
		<Stack>
			<Typography variant="h5" fontWeight={800}>
				{title}
				<Divider sx={{ borderColor: 'black', pt: 1 }} />
			</Typography>
			<Blue fontSize={{ xs: 54, md: 75 }} fontWeight={800}>
				{number}
			</Blue>
			<Typography fontSize={18} variant="caption">
				with SavedBy Package Protection
			</Typography>
		</Stack>
	);
}
Data.propTypes = {
	title: PropTypes.string,
	number: PropTypes.string,
};
