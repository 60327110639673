import React from 'react';
import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function FileClaimButton({
	align = 'center',
	sx,
	to = '/file-a-claim',
	...rest
}) {
	return (
		<Stack alignItems={align} {...rest}>
			<Button
				variant="contained"
				LinkComponent={Link}
				size="large"
				sx={{
					width: '100%',
					borderRadius: '60px',
					background: 'linear-gradient(135deg, #0DB2FA 0%, #0357FF 100%)',
					...sx,
				}}
				to={to}
			>
				<b>FILE A CLAIM</b>
			</Button>
		</Stack>
	);
}

FileClaimButton.propTypes = {
	align: PropTypes.string,
	sx: PropTypes.object,
	rest: PropTypes.object,
	to: PropTypes.string,
};
