import React, { useEffect, useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Grid,
	Link,
	Stack,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { withErrorBoundary } from '@sentry/react';

function ProductUpsell({ upsellConfig }) {
	const [products, setProducts] = useState([]);

	useEffect(() => {
		if (!upsellConfig) return;
		Promise.all(
			upsellConfig.upsellProducts.map((p) =>
				fetch(p + '.json')
					.then((r) => r.json())
					.catch(() => false),
			),
		).then((products) => setProducts(products.filter(Boolean)));
	}, [upsellConfig]);

	return (
		<CardContent sx={{ bgcolor: '#fdf4e8', color: 'black' }}>
			<CardHeader title="You may also like:" />
			<Grid container spacing={1}>
				{products.map(({ product }, i) => (
					<Grid item xs={6} key={i}>
						<Link
							href={
								upsellConfig.upsellProducts[i] +
								(upsellConfig.discountCode
									? '?discount=' + upsellConfig.discountCode
									: '')
							}
							style={{ textDecoration: 'none' }}
						>
							<Card sx={{ height: '100%', bgcolor: 'white' }}>
								<Stack justifyContent="space-between" height="100%">
									<CardMedia
										sx={{
											height: 256,
											objectFit: 'contain',
											bgcolor: 'white',
											p: 1,
										}}
										src={product.image.src}
										component="img"
									/>
									<CardContent
										sx={{
											bgcolor: 'white',
											color: 'black',
											textAlign: 'center',
											flex: 0,
										}}
									>
										<Typography variant="body">
											<b>{product.title}</b>
										</Typography>
									</CardContent>
									<Box
										sx={{
											color: 'white',
											bgcolor: '#222B45',
											p: 2,
											textAlign: 'center',
											fontSize: '1.2em',
											flex: 0,
										}}
									>
										<b>{upsellConfig.CTA}</b>
									</Box>
								</Stack>
							</Card>
						</Link>
					</Grid>
				))}
				<Grid item xs={12}>
					{upsellConfig && (
						<Box sx={{ pt: 2 }}>
							<Link
								href={
									upsellConfig.moreLink?.url +
									(upsellConfig.discountCode
										? '?discount=' + upsellConfig.discountCode
										: '')
								}
							>
								<Typography variant="h5" textAlign="right">
									{upsellConfig.moreLink.label}
								</Typography>
							</Link>
						</Box>
					)}
				</Grid>
			</Grid>
		</CardContent>
	);
}

ProductUpsell.propTypes = {
	upsellConfig: PropTypes.object,
};

export default withErrorBoundary(ProductUpsell);
