import {
	CheckRounded,
	CloseRounded,
	QuestionMarkRounded,
} from '@mui/icons-material';
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import React from 'react';
import {
	BookButton,
	SectionHeader,
	SectionText,
	SectionTitle,
	useBreakpoint,
} from 'components';

export const CoverageShippingIssues = () => {
	const bp = useBreakpoint();

	function createData(customer, savedby, theOtherGuys) {
		return { customer, savedby, theOtherGuys };
	}
	const rows = [
		createData(
			'A+ Customer Service',
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
			<QuestionMarkRounded sx={{ strokeWidth: 1, stroke: 'black' }} />,
		),
		createData(
			'Quick Claim Resolution',
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
			<CloseRounded sx={{ strokeWidth: 2, stroke: 'red' }} />,
		),
		createData(
			'Easy Integration',
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
			<CloseRounded sx={{ strokeWidth: 2, stroke: 'red' }} />,
		),
		createData(
			'Simple Re-Orders',
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
			<CloseRounded sx={{ strokeWidth: 2, stroke: 'red' }} />,
		),
		createData(
			'Lost Packages',
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
		),
		createData(
			'Damaged Items',
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
		),
		createData(
			'Stolen Items',
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
		),
		createData(
			'Return to Sender',
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
			<CloseRounded sx={{ strokeWidth: 2, stroke: 'red' }} />,
		),
		createData(
			'International',
			<CheckRounded sx={{ strokeWidth: 2, stroke: 'blue' }} />,
			<CloseRounded sx={{ strokeWidth: 2, stroke: 'red' }} />,
		),
		createData(
			'Pricing',

			<Box>
				<Typography fontSize={24}>
					<b>$0</b> <br />
					Seriously.
				</Typography>
				<Box display="flex" justifyContent="center" marginTop={4}>
					<BookButton align="center" width={100} />
				</Box>
			</Box>,
			<Typography fontSize={20} mt={-10}>
				<b>$$$$</b>
			</Typography>,
		),
	];

	return (
		<Box pb={bp.isMd ? 5 : 2} paddingX={bp.isMd ? 2 : 0}>
			<SectionTitle>WHY CHOOSE SAVEDBY?</SectionTitle>
			<SectionHeader>
				{bp.isSm
					? 'Coverage for every major\nshipping issue.'
					: 'Coverage for\nevery major\nshipping issue.'}
			</SectionHeader>
			<SectionText width={bp.isSm ? '95%' : '100%'}>
				{bp.isSm
					? 'SavedBy Protection will re-order from your website or issue a refund to	your customer\nand will respond to all customer claims within 24 hours.'
					: 'SavedBy Protection will re-order from your\nwebsite or issue a refund to	your customer\nand will respond to all customer claims\nwithin 24 hours.'}
			</SectionText>
			<Box
				display={bp.isMd ? 'flex' : 'block'} // Use flex for desktop (md) screens
				justifyContent={bp.isMd ? 'space-around' : 'center'} // Use space-around for desktop (md) screens
				marginTop={5}
				sx={{ background: 'linear-gradient(180deg, #f7faff 0%, #e3edff 100%)' }}
			>
				<TableContainer
					component={Paper}
					sx={{
						background: 'linear-gradient(180deg, #f7faff 0%, #e3edff 100%)',
						border: 'none',
						boxShadow: 'none',
						marginTop: 5,
					}}
				>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell style={lastCellStyle} />
								<TableCell
									align="center"
									className="saved-by"
									sx={{
										...lastCellStyle,
										bgcolor: 'white',
										borderTopLeftRadius: 10,
										borderTopRightRadius: 10,
										borderBottomLeftRadius: 0,
										borderBottomRightRadius: 0,
										boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.05)',
										color: '#0357FF',
										fontSize: '20px',
										fontWeight: '700',
										width: { sm: 300, xs: false },
									}}
								>
									SavedBy.io
								</TableCell>
								<TableCell align="center" style={lastCellStyle}>
									The other guys
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, index) => (
								<TableRow align="center" key={index}>
									<TableCell
										component="th"
										scope="row"
										align="left"
										style={index === rows?.length - 1 ? lastCellStyle : {}}
										sx={{
											verticalAlign: 'top',
											marginTop: 20,
										}}
									>
										{row.customer}
									</TableCell>
									<TableCell
										align="center"
										bgcolor="white"
										width={150}
										style={index === rows?.length - 1 ? lastCellStyle : {}}
										sx={{
											mt: -5,
											boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.05)',
										}}
									>
										{row.savedby}
									</TableCell>
									<TableCell
										align="center"
										style={index === rows?.length - 1 ? lastCellStyle : {}}
									>
										{row.theOtherGuys}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Box>
	);
};

const lastCellStyle = {
	borderBottom: 'none',
	borderBottomLeftRadius: '10px',
	borderBottomRightRadius: '10px',
};

export default CoverageShippingIssues;
