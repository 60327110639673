import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

export default function ZenDesk() {
	const theme = useTheme();

	useEffect(() => {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src =
			'https://static.zdassets.com/ekr/snippet.js?key=863f3da5-9067-4df2-b32e-6634323862f0';
		script.id = 'ze-snippet';

		document.body.append(script);
	}, []);

	return (
		<Box
			sx={{
				position: 'relative',
				backgroundColor: theme.palette.alternate.main,
				backgroundImage: `linear-gradient(120deg, ${theme.palette.alternate.dark} 0%, ${theme.palette.background.paper} 100%)`,
				marginTop: -13,
				paddingTop: 13,
			}}
		></Box>
	);
}
