import React from 'react';
import Slider from 'react-slick';
import { Blue, Br, SectionHeader, useBreakpoint } from 'components';
import { Box, Card, Rating, Stack, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

export default function TrustedByThousands() {
	const bp = useBreakpoint();
	const testimonials = [
		{
			message:
				'Thank you so much for getting this handled so quickly!! Way better than dealing with USPS!',
			name: '— Jennifer S.',
		},
		{
			message:
				'Appreciate you taking care of me and getting that sent out again, looking forward to finally getting my product!',
			name: '— Kyle C.',
		},
		{
			message:
				'That was so quick! Thanks again for your help and taking care of me today!',
			name: '— Veronica Y.',
		},
		{
			message:
				'Thank you! I received my new shirts just in time for my sons birthday party.',
			name: '— Carol T.',
		},
		{
			message:
				'That was quick - Tell Caitlyn thanks for all her help. 5 star service!',
			name: '— Donny B.',
		},
	];

	const CustomPrevArrow = (props) => {
		const { onClick } = { ...props };
		return (
			<Stack
				sx={{
					position: 'absolute',
					top: '35%',
					left: bp.isMdOnly ? '0%' : bp.isLgOnly ? '0%' : '0%',
					cursor: 'pointer',
					zIndex: 1,
				}}
				onClick={onClick}
			>
				<KeyboardArrowLeft
					sx={{
						fontSize: bp.isSm ? '56px' : '44px',
						color: '#38B6FF',
					}}
				/>
			</Stack>
		);
	};

	const CustomNextArrow = (props) => {
		const { onClick } = { ...props };
		return (
			<Stack
				sx={{
					position: 'absolute',
					top: '35%',
					right: bp.isMdOnly ? '0%' : bp.isLgOnly ? '0%' : '0%',
					cursor: 'pointer',
					zIndex: 1,
				}}
				onClick={onClick}
			>
				<KeyboardArrowRight
					sx={{
						fontSize: bp.isSm ? '56px' : '44px',
						color: '#38B6FF',
					}}
				/>
			</Stack>
		);
	};

	/**
	 * @type {import("react-slick").Settings}
	 */
	const settings = {
		arrows: true,
		dots: true,
		autoplay: true,
		swipeToSlide: true,
		pauseOnHover: true,
		pauseOnDotsHover: true,
		nextArrow: <CustomNextArrow />,
		prevArrow: <CustomPrevArrow />,
		responsive: [
			{
				breakpoint: 750,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 3800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<Box
			sx={{
				pt: { xs: 4, md: 6 },
				pb: { xs: 10, md: 12 },
				background: 'linear-gradient(#F7FAFF 0%, #E3EDFF 100%)',
				textAlign: 'center',
			}}
		>
			<Stack
				spacing={4}
				sx={{ width: '100%', maxWidth: '1500px', margin: 'auto' }}
			>
				<Stack>
					<SectionHeader
						lineHeight={{ xs: '40px !important', md: '66px !important' }}
						fontSize={{ xs: '36px !important', md: '60px !important' }}
					>
						We’re <Blue>trusted by thousands</Blue>
						<Br xs /> of customers daily
					</SectionHeader>
				</Stack>
				<Slider {...settings}>
					{testimonials.map((testimonial, key) => (
						<Stack
							key={key}
							px={{ xs: 2, md: 2 }}
							display="flex !important"
							alignItems="center"
							justifyContent="center"
							maxWidth={330}
							m="auto"
						>
							<Card
								sx={{
									borderRadius: '20px',
									height: '100%',
									minHeight: bp.isSm ? '252px' : '220px',
									width: bp.isSm ? '325px' : '295px',
									p: 4,
									mb: 2,
								}}
							>
								<Stack
									spacing={3}
									sx={{
										justifyContent: 'space-between',
										textAlign: 'left',
									}}
								>
									<CustomerRating />
									<Typography>{testimonial.message}</Typography>
									<Typography variant="caption">{testimonial.name}</Typography>
								</Stack>
							</Card>
						</Stack>
					))}
				</Slider>
			</Stack>
		</Box>
	);
}

function CustomerRating() {
	return (
		<Box
			sx={{
				width: 200,
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<Rating
				value={5} // Set the default value to 5 stars
				readOnly // Set the rating to be read-only
				sx={{
					color: '#0357FF', // Set the color to blue
				}}
			/>
		</Box>
	);
}
