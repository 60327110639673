import React from 'react';
import { CardContent, Stack, Typography } from '@mui/material';

function PoweredBy() {
	return (
		<CardContent sx={{ textAlign: 'center' }}>
			<Stack>
				<Typography variant="caption" fontWeight={600}>
					Powered By
				</Typography>
				<img
					width="140px"
					style={{ margin: 'auto' }}
					src="https://cdn.savedby.io/SavedByLogo3.png"
				/>
			</Stack>
		</CardContent>
	);
}

export default PoweredBy;
