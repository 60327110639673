import React from 'react';
import {
	Box,
	Grid,
	Container,
	Stack,
	Button,
	CardMedia,
	Card,
	Typography,
} from '@mui/material';
import { Blue, Br, FileClaimButton } from 'components';
import { useNavigate } from 'react-router-dom';

export default function Hero() {
	const nav = useNavigate();
	return (
		<Box
			sx={{
				pt: 14,
				pb: 9,
				color: 'white',
				background: 'linear-gradient(#2A415F 0%, #1A2237 100%)',
			}}
		>
			<Container>
				<Grid container spacing={6}>
					<Grid item xs={12} md={6} textAlign={{ xs: 'center', md: 'left' }}>
						<Stack spacing={4} sx={{ height: '100%' }} justifyContent="center">
							<Typography variant="h3" fontWeight={800}>
								Protect your orders
								<Br xs sm={false} /> from <Blue>anything.</Blue>
							</Typography>

							<Typography variant="body1">
								SavedBy Package Protection weathers
								<Br xs sm={false} /> any storm your package may meet. Leave
								<Br xs sm={false} /> the stress to us!
							</Typography>

							<Stack
								direction="row"
								justifyContent={{ xs: 'center', md: 'left' }}
								spacing={2}
							>
								<FileClaimButton width={200} />
								<Button
									fullWidth
									variant="outlined"
									onClick={() => nav('/policies', window.scrollTo(0, 0))}
								>
									Policy
								</Button>
							</Stack>
						</Stack>
					</Grid>
					<Grid item xs={12} md={6}>
						<Card sx={{ aspectRatio: '1', borderRadius: 10 }}>
							<CardMedia
								component="video"
								autoPlay
								loop
								muted
								playsInline
								sx={{
									borderRadius: 10,
									objectFit: 'cover',
									height: '100%',
								}}
								image={'/Theft-video.mp4'}
							/>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
