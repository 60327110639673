import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Blue, Br } from 'components';
import ContactForm from 'components/ContactForm';

export default function Footer() {
	return (
		<Grid container spacing={4}>
			<Grid item container alignItems={'center'} xs={12} md={6}>
				<Box pt={{ xs: 0, md: 4 }}>
					<Box
						display={'flex'}
						component="a"
						href="/"
						title="SavedBy"
						width={{ xs: 225, sm: 250 }}
					>
						<Box
							component={'img'}
							src="https://cdn.savedby.io/SavedByLogo3.png"
							height={1}
							width={1}
						/>
					</Box>
					<Typography
						variant="h2"
						gutterBottom
						color={'white'}
						pt={4}
						sx={{
							fontWeight: 700,
						}}
					>
						Save Time and <Br /> Money. <Blue>Period.</Blue>
					</Typography>
					<Typography variant="h6" color={'white'} sx={{ fontWeight: 400 }}>
						Protect orders, increase conversion rate, <Br md /> boost customer
						loyalty, and start saving time <Br md /> and money in the process.
					</Typography>
					<Typography
						component="p"
						color={'white'}
						sx={{
							fontWeight: 400,
							mt: 4,
							fontSize: 12,
						}}
					>
						Have a question? Send us an <br /> email to{' '}
						<a
							href="mailto:support@savedby.io"
							style={{ textDecoration: 'none', color: 'white' }}
						>
							support@savedby.io
						</a>
						.
					</Typography>
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				mt={{ xs: 5, sm: 8 }}
				md={6}
				display={'flex'}
				justifyContent={'center'}
				alignItems={'center'}
				bgcolor=""
				sx={{ position: 'relative', borderRadius: 40 }}
			>
				{/* <Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						borderRadius: 8,
						background: 'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
						opacity: 0.1, // Adjust the opacity value as per your preference
						justifyContent: 'center', // Center horizontally
						alignItems: 'center', // Center vertically
					}}
				/> */}
				<ContactForm />
			</Grid>
			<Grid item>
				<Typography
					component="p"
					color={'white'}
					sx={{
						fontFamily: 'Inter',
						fontSize: 12,
						fontStyle: 'normal',
						fontWeight: 400,
						lineHeight: 'normal',
						mt: 4,
						padding: 2,
						border: '1px solid rgba(217, 217, 217, 0.2)', // Add the border property with opacity
					}}
				>
					When you visit or interact with our sites, services or tools, we or
					our authorized service providers may use cookies for storing
					information to help provide you with a better, faster and safer
					experience and for marketing purposes.
				</Typography>
			</Grid>
			<Grid item container spacing={1} pt={4}>
				{/* Left Grid Item */}
				<Grid item xs={12} md={5}>
					<Typography
						sx={{
							fontSize: 14,
							color: 'white',
							textAlign: { xs: 'center', md: 'left' },
						}}
					>
						© SavedBy 2023. All rights reserved
					</Typography>
				</Grid>

				<Grid item xs={12} md>
					<Link
						to="/tos"
						onClick={() => window.scroll(0, 20)}
						style={{ textDecoration: 'none' }}
					>
						<Typography
							sx={{
								fontSize: 14,
								color: 'white',
								textAlign: 'center',
							}}
						>
							Terms and Conditions
						</Typography>
					</Link>
				</Grid>

				<Grid item xs={12} md>
					<Link
						to="/privacy-policy"
						onClick={() => window.scroll(0, 0)}
						style={{ textDecoration: 'none' }}
					>
						<Typography
							sx={{
								fontSize: 14,
								color: 'white',
								textAlign: 'center',
							}}
						>
							Privacy Policy
						</Typography>
					</Link>
				</Grid>

				<Grid item xs={12} md>
					<Link
						to="/shopify-app-privacy-policy"
						onClick={() => window.scroll(0, 0)}
						style={{ textDecoration: 'none' }}
					>
						<Typography
							sx={{
								fontSize: 14,
								color: 'white',
								textAlign: 'center',
							}}
						>
							Shopify App Privacy Policy
						</Typography>
					</Link>
				</Grid>
			</Grid>
		</Grid>
	);
}
