import React from 'react';
import { Card, Container, Stack, useMediaQuery } from '@mui/material';
import { Image } from 'mui-image';
import {
	BookButton,
	SectionHeader,
	SectionText,
	SectionTitle,
	useBreakpoint,
} from 'components';

const IntegrationConnect = () => {
	const bp = useBreakpoint();
	const isLargish = useMediaQuery('(min-width:1075px) and (max-width:1199px)');
	return (
		<Stack justifyContent="center">
			<SectionTitle>INTEGRATIONS</SectionTitle>
			<SectionHeader>
				{bp.isMd
					? 'Seamlessly connect to the\napps you use.'
					: 'Seamlessly\nconnect to the\napps you use.'}
			</SectionHeader>
			<SectionText width={bp.isSm ? '71%' : '100%'}>
				SavedBy Package Protection easily and securely integrates into your
				workflow, servicing all major e-commerce providers. Don’t see your
				provider here or need a custom solution? Give us a call and we’ll make
				it happen!
			</SectionText>
			<Stack alignItems="center" mt={1}>
				<BookButton align="center" />
			</Stack>
			<Stack
				justifyContent="space-between"
				direction={isLargish || bp.isLg ? 'row' : 'column'}
				alignItems="center"
				sx={{ mt: { xs: 4, md: 6 } }}
			>
				<Container
					maxWidth="xs"
					sx={{ display: 'flex', justifyContent: 'center' }}
				>
					<Card
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							mb: 2,
							height: bp.isLg ? 125 : 100,
							width: isLargish ? 300 : bp.isMd ? 350 : bp.isLg ? 340 : 300,
						}}
					>
						<Image
							src={'/Shopify-Logo.svg'}
							width={bp.isMd ? 200 : 150}
							height={bp.isMd ? 60 : 50}
							fit="contain"
						/>
					</Card>
				</Container>
				<Container sx={{ display: 'flex', justifyContent: 'center' }}>
					<Card
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							pl: 1,
							mb: 2,
							height: bp.isLg ? 125 : 100,
							width: isLargish ? 300 : bp.isMd ? 350 : bp.isLg ? 340 : 300,
						}}
					>
						<Image
							src={'/download.svg'}
							width={bp.isMd ? 200 : 150}
							height={50}
							fit="contain"
						/>
					</Card>
				</Container>
				<Container sx={{ display: 'flex', justifyContent: 'center' }}>
					<Card
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							mb: 2,
							height: bp.isLg ? 125 : 100,
							width: isLargish ? 300 : bp.isMd ? 350 : bp.isLg ? 340 : 300,
						}}
					>
						<Image
							src={'/rebuy-logo-gradient.svg'}
							width={bp.isMd ? 200 : 150}
							height={50}
							fit="contain"
						/>
					</Card>
				</Container>
			</Stack>
		</Stack>
	);
};

export default IntegrationConnect;
