import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Container from 'components/Container';
import { Grid } from '@mui/material';
import ContactForm from 'components/ContactForm';

const Hero = () => {
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	return (
		<Box
			sx={{
				backgroundRepeat: 'repeat-x',
				position: 'relative',
				width: '100%',
			}}
		>
			{/* Grid container for flex layout */}
			<Grid
				container
				paddingY={{ xs: 1, sm: '4rem', md: '8rem' }}
				display={{ xs: 'block', md: 'flex' }}
				justifyContent={'space-around'}
				width={'100%'}
			>
				{/* Left side content */}
				<Grid item xs={12} md={6}>
					<Container>
						<Box paddingY={5}>
							<Typography
								color={'#38B6FF'}
								variant="h5"
								fontWeight={500}
								href={'/book-a-call'}
								textAlign={isMd ? 'left' : 'center'}
							>
								BOOK A CALL
							</Typography>
							<Typography
								variant="h2"
								// color="text.primary"
								gutterBottom
								pt={4}
								textAlign={isMd ? 'left' : 'center'}
								sx={{
									fontWeight: 700,
								}}
							>
								Save Time and <br /> Money.{' '}
								<Typography
									color={'primary'}
									component={'span'}
									variant={'inherit'}
								>
									Period.
								</Typography>
							</Typography>
							<Typography
								variant="h6"
								component="p"
								color="text.secondary"
								sx={{ fontWeight: 400 }}
								textAlign={isMd ? 'left' : 'center'}
							>
								Protect orders, boost customer loyalty <br /> and start saving
								time and money in the process.
							</Typography>
							<Box marginY={5}>
								<Box display={'flex'} marginY={2}>
									<LazyLoadImage src={'/Tick.svg'} />

									<Typography marginLeft={1}>
										24/7 Support & Coverage on Every Shipping Issue
									</Typography>
								</Box>
								<Box display={'flex'} marginY={2}>
									<LazyLoadImage src={'/Tick.svg'} />

									<Typography marginLeft={1}>
										Top Tier Customer Service
									</Typography>
								</Box>
								<Box display={'flex'} marginY={2}>
									<LazyLoadImage src={'/Tick.svg'} />

									<Typography marginLeft={1}>Mitigate Your Losses</Typography>
								</Box>
								<Box display={'flex'} marginY={2}>
									<LazyLoadImage src={'/Tick.svg'} />

									<Typography marginLeft={1}>
										Increase Conversions & Customer Loyalty
									</Typography>
								</Box>
							</Box>
						</Box>
					</Container>
				</Grid>

				{/* Right side content */}
				<Grid item xs={12} md={6}>
					<ContactForm />
				</Grid>
			</Grid>
		</Box>
	);
};

export default Hero;
