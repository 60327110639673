import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import NavItem from './components/NavItem';
import { Button, Stack } from '@mui/material';

const SidebarNav = () => {
	return (
		<Stack justifyContent="space-between" sx={{ height: '100%' }}>
			<Box>
				<Box width={1} paddingX={2} paddingY={1}>
					<Box
						display={'flex'}
						component="a"
						href="/"
						title="SavedBy"
						width={{ xs: 200 }}
					>
						<Box
							component={'img'}
							src="https://cdn.savedby.io/SavedByLogo3.png"
							height={1}
							width={1}
						/>
					</Box>
				</Box>
				<Box paddingX={2} paddingY={2}>
					<NavItem title={'Protection'} href="/" />
					<NavItem title={'Policies'} href="/policies" />
					<NavItem title="File a Claim" href="/customers" />

					<NavItem
						title="Case Studies"
						isNew
						items={[
							{ title: 'Axe & Sledge', href: '/axe-case-study' },
							{ title: 'DedCool', href: '/dedcool-case-study' },
							{ title: 'Pack Leashes', href: '/pack-case-study' },
						]}
					/>
				</Box>
			</Box>

			<Box marginTop={1} textAlign="center">
				<Button
					size={'large'}
					variant="contained"
					color="primary"
					fullWidth
					component="a"
					target="blank"
					href={process.env.REACT_APP_SHOPIFY_URL}
				>
					Start Protecting
				</Button>
			</Box>
		</Stack>
	);
};

SidebarNav.propTypes = {
	pages: PropTypes.object,
};

export default SidebarNav;
