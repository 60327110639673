import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function Blue({ children, ...rest }) {
	return (
		<Typography
			color={'#38B6FF'}
			component={'span'}
			variant={'inherit'}
			{...rest}
		>
			{children}
		</Typography>
	);
}

Blue.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	rest: PropTypes.object,
};
