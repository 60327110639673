import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const Content = () => {
	return (
		<Container maxWidth="md">
			<Box>
				SavedBy Package Protection {'"'}the App{'"'} provides shipping
				protection services {'"'}the Service{'"'} to merchants who use Shopify
				to power their stores. This Privacy Policy describes how personal
				information is collected, used, and shared when you install or use the
				App in connection with your Shopify-supported store.
				<br />
				<br />
				Personal Information the App collects
				<br />
				<br />
				When you install the App, we are automatically able to access certain
				types of information from your Shopify account:
				<ul>
					<li>
						Orders - To determine if the SavedBy service fee was charged, and to
						be able to refund or reorder based on conversations had with the
						merchant and the customer.
					</li>
					<li>
						Products - To create the SavedBy Package Protection product, which
						allows customers to pay a fee in order to be covered by SavedBy.
					</li>
					<li>
						Customers - To determine if the customer information matches the
						claim made, and to create a future order for the customer.
					</li>
					<li>
						Themes - To install our script in the theme so it is purchasable by
						the customer.
					</li>
					<li>Script Tags - To see our injected scripts (widget).</li>
					<li>
						Price Rules/Discounts - To allow us to exclude our SavedBy Package
						Protection product from price rules or discounts that are active on
						merchant’s sites.
					</li>
					<li>
						Fulfillments - To view order fulfillment status and to automatically
						fulfill the SavedBy product upon fulfillment of the rest of the
						order
					</li>
					<li>
						Gift Cards - To allow read and creation of gift cards for claims as
						store credit.
					</li>
				</ul>
				Additionally, we collect the following types of personal information
				from you and/or your customers once you have installed the App:
				<ul>
					<li>
						Claim info - To determine if the customer’s order falls within the
						policies as described on Savedby.io.
					</li>
					<li>
						New address if need be - To send a replacement package to the
						correct address for the customer.
					</li>
				</ul>
				Information about you and others who may access the App on behalf of
				your store, such as your name, address, email address, phone number, and
				billing information; Information about individuals who visit your store,
				such as their IP address, web browser details, time zone, and
				information about the cookies installed on the particular device.
				<br />
				<br />
				We collect personal information directly from the relevant individual,
				through your Shopify account, or using the following technologies:
				“Cookies” are data files that are placed on your device or computer and
				often include an anonymous unique identifier. For more information about
				cookies, and how to disable cookies, visit
				http://www.allaboutcookies.org. “Log files” track actions occurring on
				the Site, and collect data including your IP address, browser type,
				Internet service provider, referring/exit pages, and date/time stamps.
				“Web beacons,” “tags,” and “pixels” are electronic files used to record
				information about how you browse the Site.
				<br />
				<br />
				<Typography variant="h4">
					How Do We Use Your Personal Information?
				</Typography>
				<br />
				We use the personal information we collect from you and your customers
				in order to provide the Service and to operate the App. Additionally, we
				use this personal information to: Communicate with you; and to Optimize
				or improve the App
				<br />
				<br />
				<Typography variant="h4">Sharing Your Personal Information</Typography>
				<br />
				Personal information will not be shared with third parties. Personal
				information will only be used by the SavedBy App, the Merchant, and
				Shopify.
				<br />
				<br />
				Finally, we may also share your Personal Information to comply with
				applicable laws and regulations, to respond to a subpoena, search
				warrant or other lawful request for information we receive, or to
				otherwise protect our rights.
				<br />
				<br />
				Your Rights If you are a European resident, you have the right to access
				personal information we hold about you and to ask that your personal
				information be corrected, updated, or deleted. If you would like to
				exercise this right, please contact us through the contact information
				below.
				<br />
				<br />
				Additionally, if you are a European resident we note that we are
				processing your information in order to fulfill contracts we might have
				with you (for example if you make an order through the Site), or
				otherwise to pursue our legitimate business interests listed above.
				Additionally, please note that your information will be transferred
				outside of Europe, including to Canada and the United States.
				<br />
				<br />
				Data Retention When you place an order through the Site, we will
				maintain your Order Information for our records unless and until you ask
				us to delete this information.
				<br />
				<br />
				Changes We may update this privacy policy from time to time in order to
				reflect, for example, changes to our practices or for other operational,
				legal or regulatory reasons.
				<br />
				<br />
				Contact Us For more information about our privacy practices, if you have
				questions, or if you would like to make a complaint, please contact us
				by email at support@savedby.io or by mail using the details provided
				below:
				<br />
				<br />
				SavedBy Package Protection
				<br />
				<br />
				2255 Sheridan Blvd.
				<br />
				<br />
				Unit C246
				<br />
				<br />
				Edgewater, CO 80214
				<br />
				<br />
			</Box>
		</Container>
	);
};

export default Content;
