import React from 'react';
import {
	Box,
	Card,
	CardMedia,
	Container,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { Blue, Br, SectionTitle } from 'components';

export default function FromTheClient() {
	return (
		<Container
			sx={{
				textAlign: 'center',
				pb: 10,
			}}
		>
			<Grid container spacing={{ xs: 4, sm: 6, md: 10, lg: 8 }}>
				<Grid item xs={12}>
					<Stack spacing={2}>
						<SectionTitle>FROM THE CLIENT</SectionTitle>
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Card sx={{ aspectRatio: 'auto', borderRadius: 10 }}>
						<CardMedia component="img" src="DedCoolProduct4.png" />
					</Card>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack spacing={2} height="100%" justifyContent="center">
						<Typography
							variant="h4"
							gutterBottom
							fontWeight={800}
							textAlign="left"
						>
							Communication with the SavedBy Team
						</Typography>
						<Typography textAlign="left">
							“Easy communication and quick service for customers! I no longer
							have to worry if sending a customer to SavedBy will create more
							complications.
							<Blue px={0}>
								<b>
									{' '}
									The SavedBy team has been so quick to help me with questions I
									have and customers with their issues.
								</b>
							</Blue>{' '}
							I can relax knowing the customers will be taken care of.”
						</Typography>

						<Typography
							variant="h4"
							gutterBottom
							fontWeight={800}
							textAlign="left"
						>
							Customer Experience with SavedBy
						</Typography>
						<Typography textAlign="left">
							“Not one person has been upset about having to reach out to
							SavedBy and what&apos;s great is I can help them easily and
							SavedBy takes care of them quickly.
							<Blue px={0}>
								<b> I have received no complaints.</b>
							</Blue>
							”
						</Typography>

						<Box
							sx={{
								borderLeftStyle: 'solid',
								borderLeftColor: '#38B6FF',
								borderLeftWidth: 4,
								textAlign: 'left',
								py: 1,
								pl: 2,
							}}
						>
							<Typography variant="h5">
								<b>Gabriela</b>
							</Typography>
							<Typography>Head of CX</Typography>
						</Box>
					</Stack>
				</Grid>

				<Grid item xs={12} mt={2}>
					<Stack spacing={2} sx={{ alignItems: 'center' }}>
						<Typography
							variant="h4"
							textAlign="center"
							fontWeight={800}
							color="#38B6FF"
							px={{ xs: 0, sm: 4, md: 10 }}
						>
							Since making the transition to SavedBy, DedCool has experienced
							several notable advantages
						</Typography>
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack>
						<Stack direction="row" spacing={2}>
							<StarIcon sx={{ color: '#38B6FF' }} />
							<Typography textAlign="left">
								Easy Communication and Quick Service for Customers:
								DedCool&apos;s primary concern was ensuring that customers&apos;
								issues related to package issues were resolved swiftly and
								efficiently.
							</Typography>
						</Stack>
						<Br xs />
						<Stack direction="row" spacing={2}>
							<StarIcon sx={{ color: '#38B6FF' }} />
							<Typography textAlign="left">
								When asked if there was anything they would change about the
								SavedBy service, Gabriela said:{' '}
								<i>
									&quot;I wouldn&apos;t change a thing, the experience has been
									great!&quot;
								</i>
							</Typography>
						</Stack>
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack sx={{ alignItems: 'center' }}>
						<Stack direction="row" spacing={2}>
							<StarIcon sx={{ color: '#38B6FF' }} />
							<Typography textAlign="left">
								DedCool expressed gratitude for making the transition to
								SavedBy. The ease of working with SavedBy and the resulting
								improvements in customer service operations and customer
								experience have proven to be a valuable asset to their team.
							</Typography>
						</Stack>
						<Br xs />
						<Stack direction="row" spacing={2}>
							<StarIcon sx={{ color: '#38B6FF' }} />
							<Typography textAlign="left">
								SavedBy provides a seamless and hassle-free experience for both
								DedCool&apos;s customer service team and their customers.
							</Typography>
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	);
}
