import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ZenDesk from './ZenDesk';
import Routes from './Routes';
import Page from './components/Page';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

const App = () => {
	return (
		<Page>
			<BrowserRouter>
				<ZenDesk />
				<Routes />
			</BrowserRouter>
		</Page>
	);
};

export default App;
