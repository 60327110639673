import React from 'react';
import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useBreakpoint from './useBreakpoint';

export default function BookButton({ align = 'center', sx, ...rest }) {
	const bp = useBreakpoint();
	return (
		<Stack alignItems={align} {...rest}>
			<Button
				variant="contained"
				LinkComponent={Link}
				size={bp.isSm ? 'large' : 'small'}
				sx={{
					width: { xs: '100%', md: 200 },
					borderRadius: '60px',
					background: 'linear-gradient(135deg, #0DB2FA 0%, #0357FF 100%)',
					...sx,
				}}
				to={'/book-a-call'}
			>
				<b>BOOK A CALL</b>
			</Button>
		</Stack>
	);
}

BookButton.propTypes = {
	align: PropTypes.string,
	sx: PropTypes.object,
	rest: PropTypes.object,
};
