import { Box, Container, Hidden, Typography } from '@mui/material';
import React from 'react';
import { CoverageShippingIssues } from 'views/LandingPage/components/CoverageShippingIssues/CoverageShippingIssues';

import { PercentageCard } from 'views/LandingPage/components/SavedByHasYouCovered/FlexContainer/FlexContainer';
import Hero from './components/Hero';
import LogoSection from './components/LogoSection';
import { ShippingIssuesLogos } from './components/ShippingIssuesLogos';

const BookACallPage = () => {
	return (
		<Box sx={{ py: 0 }}>
			{/* Hero section */}
			<Hidden xsDown>
				{/* Show Hero section only on screens larger than xs breakpoint (i.e., mobile screens) */}
				<Box
					sx={{
						background: 'linear-gradient(180deg, #f7faff 0%, #e3edff 100%)',
						marginTop: '-23px',
					}}
				>
					<Container>
						<Hero />
					</Container>
				</Box>
			</Hidden>

			{/* Logos section */}
			<Box bgcolor={'#F7FAFF'} py={{ xs: 2, md: 4 }}>
				<Typography
					sx={{ py: 2 }}
					textAlign="center"
					color="#1A2237"
					fontSize={18}
					fontWeight={900}
				>
					TRUSTED BY BRANDS LIKE
				</Typography>
				<LogoSection />
			</Box>

			{/* Shipping Issues Logos section */}
			<Box marginY={10}>
				<Container>
					<ShippingIssuesLogos />
				</Container>
			</Box>

			{/* NumberContainer section */}
			<Box marginY={10}>
				<Container>
					<PercentageCard />
				</Container>
			</Box>

			{/* CoverageShippingIssues section */}
			<Box
				sx={{
					background: 'linear-gradient(180deg, #f7faff 0%, #e3edff 100%)',
					mt: 10,
				}}
			>
				<Container>
					<CoverageShippingIssues />
				</Container>
			</Box>
		</Box>
	);
};

export default BookACallPage;
