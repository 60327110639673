import { LandLayout, CustomerLayout } from 'layouts';
import CaseStudyLayout from 'layouts/CaseStudyLayout/CaseStudyLayout';
import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import {
	PoliciesView,
	FileClaimView,
	TrackView,
	TosView,
	PrivacyPolicyView,
	ShopifyAppPrivacyPolicyView,
	LandingPage,
	CustomersView,
	AxeCaseStudyView,
	BookACallPage,
	PackCaseStudyView,
	DedCoolStudyView,
} from 'views';
import TOSPDF from 'views/TOSPDF';

/**
 *
 * @param {number} permission
 * @returns {import("react-router-dom").RouteObject[]}
 */
export const routes = () => {
	return [
		{
			path: '/',
			element: <LandLayout />,
			children: [
				{
					path: '/',
					element: <LandingPage />,
				},
				{
					path: 'book-a-call',
					element: <BookACallPage />,
				},
				{
					path: 'tos',
					element: <TosView />,
				},
				{
					path: 'privacy-policy',
					element: <PrivacyPolicyView />,
				},
				{
					path: 'shop-app-privacy-policy',
					element: <Navigate to="/shopify-app-privacy-policy" />,
				},
				{
					path: 'shopify-app-privacy-policy',
					element: <ShopifyAppPrivacyPolicyView />,
				},
			],
		},
		{
			path: '/',
			element: <CaseStudyLayout />,
			children: [
				{
					path: 'case-study',
					element: <Navigate to="/axe-case-study" />,
				},
				{
					path: 'axe-case-study',
					element: <AxeCaseStudyView />,
				},
				{
					path: 'pack-case-study',
					element: <PackCaseStudyView />,
				},
				{
					path: 'dedcool-case-study',
					element: <DedCoolStudyView />,
				},
			],
		},
		{
			path: '/',
			element: <CustomerLayout />,
			children: [
				{
					path: 'customers',
					element: <CustomersView />,
				},
				{
					path: 'file-a-claim',
					element: <FileClaimView />,
				},
				{
					path: 'policies',
					element: <PoliciesView />,
				},
			],
		},
		{
			path: '/merchants/tos',
			element: <TOSPDF />,
		},
		{
			path: 'track',
			element: <TrackView />,
		},
		{
			path: '*',
			element: <Navigate replace to="/" />,
		},
	];
};

export default function Routes() {
	const routing = useRoutes(routes());

	return routing;
}
