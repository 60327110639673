import React from 'react';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Blue, Br, FileClaimButton, useBreakpoint } from '../../../components';

export default function Footer() {
	const nav = useNavigate();
	const bp = useBreakpoint();
	return (
		<Container
			sx={{
				color: 'white',
				textAlign: 'center',
				py: { xs: 1, sm: 4 },
			}}
		>
			<Stack spacing={4} alignItems="center">
				<Box width={{ xs: 200 }}>
					<img
						src="https://cdn.savedby.io/SavedByLogo3.png"
						alt="SavedBy Logo"
						style={{
							width: '100%',
						}}
					/>
				</Box>

				<Container
					maxWidth="md"
					sx={{
						px: '0px !important',
						mt: { xs: '8px !important', sm: '24px !important' },
					}}
				>
					<Typography variant="h2" fontWeight={800}>
						Get Resolution for Your
						<Br md /> Orders <Blue>in Minutes.</Blue>
					</Typography>
				</Container>

				<Stack direction="row" spacing={2} justifyContent="center">
					<FileClaimButton sx={{ width: bp.isSm ? '200px' : '150px' }} />
					<Button
						variant="outlined"
						sx={{ width: bp.isSm ? '200px' : '150px' }}
						onClick={() => nav('/policies', window.scrollTo(0, 0))}
					>
						policy
					</Button>
				</Stack>

				<Typography
					variant="body1"
					sx={{ fontWeight: 400, mt: '64px !important' }}
				>
					Have a question? Send us an email to{' '}
					<a href="mailto:support@savedby.io" style={{ color: 'white' }}>
						support@savedby.io
					</a>
					.
				</Typography>

				<Typography
					sx={{
						fontSize: 12,
						fontStyle: 'normal',
						fontWeight: 400,
						lineHeight: 'normal',
						mt: 4,
						padding: 2,
						border: '1px solid rgba(217, 217, 217, 0.2)', // Add the border property with opacity
					}}
				>
					When you visit or interact with our sites, services or tools, we or
					our authorized service providers may use cookies for storing
					information to help provide you with a better, faster and safer
					experience and for marketing purposes.
				</Typography>

				<Grid item container spacing={1} pt={4}>
					{/* Left Grid Item */}
					<Grid item xs={12} md={5}>
						<Typography
							sx={{
								fontSize: 14,
								color: 'white',
								textAlign: { xs: 'center', md: 'left' },
							}}
						>
							© SavedBy 2023. All rights reserved
						</Typography>
					</Grid>

					<Grid item xs={12} md>
						<Link
							to="/tos"
							onClick={() => window.scroll(0, 0)}
							style={{ textDecoration: 'none' }}
						>
							<Typography
								sx={{
									fontSize: 14,
									color: 'white',
								}}
							>
								Terms and Conditions
							</Typography>
						</Link>
					</Grid>

					<Grid item xs={12} md>
						<Link
							to="/privacy-policy"
							onClick={() => window.scroll(0, 0)}
							style={{ textDecoration: 'none' }}
						>
							<Typography
								sx={{
									fontSize: 14,
									color: 'white',
								}}
							>
								Privacy Policy
							</Typography>
						</Link>
					</Grid>

					<Grid item xs={12} md>
						<Link
							to="/shopify-app-privacy-policy"
							onClick={() => window.scroll(0, 0)}
							style={{ textDecoration: 'none' }}
						>
							<Typography
								sx={{
									fontSize: 14,
									color: 'white',
								}}
							>
								Shopify App Privacy Policy
							</Typography>
						</Link>
					</Grid>
				</Grid>
			</Stack>
		</Container>
	);
}
