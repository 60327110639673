import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const NavItem = ({ title, items, href, isNew }) => {
	const theme = useTheme();
	const [activeLink, setActiveLink] = useState('');
	const hasItems = !!items?.length;
	useEffect(() => {
		setActiveLink(window && window.location ? window.location.pathname : '');
	}, []);

	const hasActiveLink = () =>
		hasItems ? items.find((i) => i.href === activeLink) : href === activeLink;

	return (
		<Box>
			<AccordionWrapper use={hasItems} items={items} theme={theme} href={href}>
				<Typography
					fontWeight={hasActiveLink() ? 600 : 400}
					color={hasActiveLink() ? 'primary' : 'text.primary'}
				>
					{title}
				</Typography>
				{isNew && (
					<Box
						p={0.5}
						display={'inline-flex'}
						borderRadius={1}
						bgcolor={'primary.main'}
						marginLeft={2}
					>
						<Typography variant={'caption'} sx={{ color: 'common.white' }}>
							NEW!
						</Typography>
					</Box>
				)}
			</AccordionWrapper>
		</Box>
	);
};

function AccordionWrapper({
	children,
	use = false,
	items,
	activeLink,
	theme,
	href,
}) {
	return use ? (
		<Accordion
			disableGutters
			elevation={0}
			sx={{ backgroundColor: 'transparent' }}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				sx={{ padding: 0 }}
			>
				{children}
			</AccordionSummary>
			<AccordionDetails sx={{ padding: 0 }}>
				<Grid container spacing={1}>
					{items?.map((p, i) => (
						<Grid item key={i} xs={12}>
							<Button
								size={'large'}
								component={'a'}
								href={p.href}
								fullWidth
								sx={{
									justifyContent: 'flex-start',
									color:
										activeLink === p.href
											? theme.palette.primary.main
											: theme.palette.text.primary,
									backgroundColor:
										activeLink === p.href
											? alpha(theme.palette.primary.main, 0.1)
											: 'transparent',
									fontWeight: activeLink === p.href ? 600 : 400,
								}}
							>
								{p.title}
								{p.isNew && (
									<Box
										p={0.5}
										display={'inline-flex'}
										borderRadius={1}
										bgcolor={'primary.main'}
										marginLeft={2}
									>
										<Typography
											variant={'caption'}
											sx={{ color: 'common.white' }}
										>
											NEW!
										</Typography>
									</Box>
								)}
							</Button>
						</Grid>
					))}
				</Grid>
			</AccordionDetails>
		</Accordion>
	) : (
		<Box component="a" href={href} sx={{ textDecoration: 'none' }}>
			<Box py={1.5}>{children}</Box>
		</Box>
	);
}
AccordionWrapper.propTypes = {
	children: PropTypes.any.isRequired,
	use: PropTypes.bool,
	items: PropTypes.array,
	activeLink: PropTypes.bool,
	theme: PropTypes.any,
	href: PropTypes.string,
};

NavItem.propTypes = {
	items: PropTypes.array,
	title: PropTypes.string.isRequired,
	onClose: PropTypes.func,
	href: PropTypes.string,
	isNew: PropTypes.bool,
};

export default NavItem;
