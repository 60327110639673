import * as React from 'react';
import {
	Typography,
	Card,
	Grid,
	CardHeader,
	CardContent,
	Stack,
	Container,
	Box,
} from '@mui/material';
import { Image } from 'mui-image';
import { Star } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'components';

export default function FlexContainer() {
	return (
		<Grid container sx={{ pt: 8 }} spacing={2}>
			<Grid item xs={12} md={6}>
				<TopTierCard />
			</Grid>
			<Grid container item xs={12} md={6} rowSpacing={2}>
				<Grid item xs={12}>
					<MitigateLossesCard />
				</Grid>
				<Grid item xs={12}>
					<IncreaseConversion />
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<PercentageCard />
			</Grid>
		</Grid>
	);
}

function TopTierCard() {
	return (
		<Card
			sx={{
				background: 'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
				borderRadius: 10,
				p: 2,
				height: '100%',
			}}
		>
			<CardHeader
				title={
					<Stack direction="row" alignItems="center" sx={{ m: -1 }}>
						<Star sx={{ color: '#37b6ff', fontSize: 34 }} />
						<Typography
							variant="h4"
							sx={{
								fontWeight: 700,
								marginLeft: 2,
							}}
						>
							Top Tier Customer Service
						</Typography>
					</Stack>
				}
			/>
			<CardContent>
				<Typography>
					When you partner with us, we take on package related customer service
					and put ourselves in the trenches, so you can focus on what matters
					most.
				</Typography>
				<Image src="/Phone.svg" sx={{ maxWidth: 312, mt: 5 }} />
			</CardContent>
		</Card>
	);
}

function MitigateLossesCard() {
	return (
		<Card
			sx={{
				background: 'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
				borderRadius: 10,
				p: 2,
			}}
		>
			<CardHeader
				title={
					<Stack direction="row" alignItems="center" sx={{ m: -1 }}>
						<img src={'/ShieldIcon.svg'} />
						<Typography
							variant="h4"
							sx={{
								fontWeight: 700,
								marginLeft: 2,
							}}
						>
							Mitigate Your Losses
						</Typography>
					</Stack>
				}
			/>
			<CardContent>
				<Typography>
					No more paying out of pocket for shipping provider mistakes. No more
					refunding or resending free packages to customers. SavedBy takes care
					of these costs entirely.
				</Typography>

				<Container maxWidth="xs" sx={{ pt: 4 }}>
					<Card sx={{ borderRadius: 5 }}>
						<CardContent>
							<Stack alignItems="center" textAlign="center">
								<img src={'/Group8.svg'} effect="blur" />
								<Typography
									color="#0357FF"
									variant="h6"
									lineHeight="1.2"
									gutterBottom
								>
									<b>
										Your New Order has <br /> been processed!
									</b>
								</Typography>
								<Typography variant="caption" lineHeight={1.2}>
									Confirmation #:
									<br />
									1501-98
								</Typography>
							</Stack>
						</CardContent>
					</Card>
				</Container>
			</CardContent>
		</Card>
	);
}

function IncreaseConversion() {
	return (
		<Card
			sx={{
				background: 'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
				borderRadius: 10,
				p: 2,
			}}
		>
			<CardHeader
				title={
					<Stack direction="row" alignItems="center" sx={{ m: -1 }}>
						<img src={'/Icon.svg'} />
						<Typography
							variant="h4"
							sx={{
								fontWeight: 700,
								marginLeft: 2,
							}}
						>
							Increase Your Conversion
						</Typography>
					</Stack>
				}
			/>
			<CardContent>
				<Typography>
					Customers have more confidence when they know their order is
					protected, simple as that.
				</Typography>

				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					paddingTop={2}
				>
					<Image src={'/Graph.svg'} />
				</Box>
			</CardContent>
		</Card>
	);
}

export function PercentageCard() {
	const bp = useBreakpoint();
	return (
		<Card
			sx={{
				background: 'linear-gradient(180deg, #F7FAFF 0%, #E3EDFF 100%)',
				borderRadius: 10,
				p: 2,
			}}
		>
			<Grid
				container
				justifyContent={bp.isMd ? 'space-evenly' : 'space-between'}
			>
				<DataPoint
					percent="93"
					label={`Decrease in customer service time spent on package issues.`}
				/>
				<DataPoint
					percent="98"
					label={`Customer satisfaction for SavedBy users.`}
				/>
				<DataPoint
					percent="100"
					label={`Reduction in costs associated with lost, stolen, or damaged packages.`}
					bp={bp}
				/>
			</Grid>
		</Card>
	);
}

function DataPoint({ percent, label }) {
	const bp = useBreakpoint();
	return (
		<Grid
			container
			item
			xs={12}
			md={3}
			pb={2}
			columnSpacing={2}
			alignItems="center"
		>
			<Grid item xs={5} md={12}>
				<Typography
					color={'#0DB2FA'}
					fontSize={bp.isMd ? 90 : 45}
					fontWeight={700}
					textAlign={bp.isSm ? 'center' : 'left'}
				>
					{percent}%
				</Typography>
			</Grid>
			<Grid
				container
				item
				xs={7}
				md={12}
				alignItems={'center'}
				justifyContent={bp.isMd ? 'center' : 'left'}
			>
				<Typography
					gutterBottom
					textAlign={bp.isMd ? 'center' : 'left'}
					fontSize={bp.isMd ? '16px' : '14px'}
				>
					{label}
				</Typography>
			</Grid>
		</Grid>
	);
}
DataPoint.propTypes = {
	percent: PropTypes.string,
	label: PropTypes.string,
};
