import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import NavItem from 'components/NavItem';
import { BookButton, FileClaimButton, useBreakpoint } from 'components';
import { Box, IconButton, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

const Topbar = ({ onSidebarOpen }) => {
	const bp = useBreakpoint();

	return (
		<Stack direction="row" alignItems="center" justifyContent="space-between">
			<Link to="/">
				<Box width={{ xs: 150, md: 250 }}>
					<img
						style={{ objectFit: 'contain' }}
						width="100%"
						src="https://cdn.savedby.io/SavedByLogo3.png"
					/>
				</Box>
			</Link>

			<Stack
				direction="row"
				spacing={{ xs: 2, md: 8 }}
				justifyContent="end"
				alignItems="center"
				width="100%"
			>
				{bp.isMd ? (
					<>
						<NavItem
							title="Case Studies"
							isNew
							items={[
								{
									href: '/axe-case-study',
									title: 'Axe & Sledge',
								},
								{
									href: '/dedcool-case-study',
									title: 'DedCool',
								},
								{
									href: '/pack-case-study',
									title: 'Pack Leashes',
								},
							]}
						/>
						<NavItem title="Policies" href="/policies" />
						<NavItem title="File a Claim" href="/customers" />
						<BookButton />
					</>
				) : (
					<>
						<FileClaimButton sx={{ px: 4, py: 0.5 }} to="/customers" />

						<IconButton
							onClick={() => onSidebarOpen()}
							aria-label="Menu"
							sx={{
								pr: 2,
							}}
						>
							<MenuIcon color="primary" />
						</IconButton>
					</>
				)}
			</Stack>
		</Stack>
	);
};

Topbar.propTypes = {
	onSidebarOpen: PropTypes.func,
	pages: PropTypes.object,
	colorInvert: PropTypes.bool,
};

export default Topbar;
