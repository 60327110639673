import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';

export default function DefaultRating() {
	return (
		<Box
			sx={{
				width: 200,
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<Rating
				value={5} // Set the default value to 5 stars
				readOnly // Set the rating to be read-only
				sx={{
					color: '#0357FF', // Set the color to blue
				}}
			/>
		</Box>
	);
}
