import React from 'react';
import {
	Stack,
	Step,
	StepContent,
	StepIcon,
	StepLabel,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { CheckCircle, Circle, CircleOutlined } from '@mui/icons-material';

function HistoryStep({ i, step, last }) {
	const Icon = () => {
		return (
			<StepIcon
				icon={
					step.substatus?.code === 'delivered' ? (
						<CheckCircle />
					) : i ? (
						<CircleOutlined />
					) : (
						<Circle />
					)
				}
			/>
		);
	};
	return (
		<Step active index={i} last={last}>
			<StepLabel
				optional={
					<Typography sx={{ opacity: 0.5 }} color={style.card.textColor}>
						{step?.status_details}
					</Typography>
				}
				StepIconComponent={Icon}
			>
				<Typography color={style.card.textColor} fontWeight={600}>
					{step.substatus?.code === 'out_for_delivery'
						? 'OUT FOR DELIVERY'
						: step.status}
				</Typography>
			</StepLabel>
			<StepContent>
				<Stack>
					{step.location && (
						<Typography color={style.card.textColor} variant="caption">
							{step.location.city}, {step.location.state}, {step.location.zip},{' '}
							{step.location.country}
						</Typography>
					)}
					<Typography variant="caption">
						{new Date(step.status_date).toLocaleString()}
					</Typography>
				</Stack>
			</StepContent>
		</Step>
	);
}

HistoryStep.propTypes = {
	i: PropTypes.number,
	step: PropTypes.object,
	last: PropTypes.bool,
};

export default HistoryStep;

const style = {
	card: {
		bgcolor: 'white',
		textColor: 'black',
		dividerColor: 'gray',
	},
	page: {
		bgcolor: false,
	},
	logoCard: {
		bgcolor: 'black',
	},
};
