import * as React from 'react';
import { Container, Stack } from '@mui/material';
import ProtectStepper from './ProtectStepper';
import FastResolutionsMobile from './FastResolutionsCustomerMobile';
import {
	Blue,
	FileClaimButton,
	SectionHeader,
	SectionText,
	SectionTitle,
	useBreakpoint,
} from 'components';

const FastResolutions = () => {
	const bp = useBreakpoint();

	return bp.isMd ? (
		<Container sx={{ pb: 8, pt: 6 }}>
			<SectionTitle>FAST RESOLUTIONS</SectionTitle>

			<Stack textAlign="center" alignItems="center" mb={4}>
				<SectionHeader>
					Protect Your Order in
					<br />
					<Blue>3 Easy Steps</Blue>
				</SectionHeader>
				<SectionText>
					SavedBy Package Protection will handle all your customer service
					team’s shipping issues. We’ll re-order from your website, or issue a
					refund to your customer and will always respond to all your customer
					claims within 24 hours.
				</SectionText>
				<FileClaimButton />
			</Stack>

			<ProtectStepper />
		</Container>
	) : (
		<FastResolutionsMobile />
	);
};

export default FastResolutions;
