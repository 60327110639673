import React from 'react';
import {
	Box,
	Card,
	CardMedia,
	Container,
	Stack,
	Typography,
} from '@mui/material';
import { SectionHeader, SectionTitle, useBreakpoint } from 'components';
import PropTypes from 'prop-types';

export default function KeyMetrics() {
	return (
		<Box
			sx={{
				pt: 8,
				pb: 10,
				background: 'linear-gradient(#F7FAFF 0%, #E3EDFF 100%)',
				textAlign: 'center',
			}}
		>
			<Container>
				<SectionTitle pt={0}>THE SAVEDBY SOLUTION</SectionTitle>
				<SectionHeader sx={{ pb: { xs: 4, sm: 8 } }}>Key Metrics</SectionHeader>

				<Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
					<OnlineConversionRate />
					<PackageCoverageRate />
				</Stack>
			</Container>
		</Box>
	);
}

function OnlineConversionRate() {
	const bp = useBreakpoint();

	return bp.isMd ? (
		<Card sx={{ pt: 4 }}>
			<CardMedia component="img" src="AxeKeyMetric1.svg" />
		</Card>
	) : (
		<>
			<Card>
				<CardMedia component="img" src="AxeKeyMetric1Mobile.svg" />
			</Card>
			<Card>
				<CardMedia component="img" src="AxeKeyMetric2Mobile.svg" />
			</Card>
		</>
	);
}

function PackageCoverageRate() {
	return (
		<Card sx={{ pt: 4 }}>
			<CardMedia component="img" src="AxeKeyMetric2.svg" />
		</Card>
	);
}

function Bubble({ children, diameter = 132 }) {
	return (
		<Box
			sx={{
				bgcolor: 'blue',
				borderRadius: '50%',
				width: diameter + 40,
				aspectRatio: '1',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderColor: '#c5eaff',
				borderWidth: 20,
				borderStyle: 'solid',
			}}
		>
			<Typography variant="h3" color="white">
				{children}
			</Typography>
		</Box>
	);
}
Bubble.propTypes = {
	children: PropTypes.string,
	diameter: PropTypes.number,
};
