import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import Container from 'components/Container';

import { Topbar } from './components';

import pages from '../navigation';
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import Sidebar from 'components/Sidebar/Sidebar';

export default function CaseStudyLayout() {
	const bgcolor = 'transparent';
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	const [openSidebar, setOpenSidebar] = useState(false);

	const handleSidebarOpen = () => {
		setOpenSidebar(true);
	};

	const handleSidebarClose = () => {
		setOpenSidebar(false);
	};

	const open = isMd ? false : openSidebar;

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 38,
	});

	return (
		<Box>
			<AppBar
				position={'fixed'}
				sx={{
					top: 0,
					backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
				}}
				elevation={trigger ? 1 : 0}
			>
				<Container
					paddingY={1}
					paddingX={{ xs: 0, sm: 2 }}
					maxWidth={{ sm: 900, md: 1200 }}
				>
					<Topbar
						onSidebarOpen={handleSidebarOpen}
						pages={pages}
						colorInvert={!trigger}
					/>
				</Container>
			</AppBar>
			<Sidebar
				onClose={handleSidebarClose}
				open={open}
				variant="temporary"
				pages={pages}
			/>
			<Box>
				<Outlet />
			</Box>
			<Box sx={{ backgroundColor: '#1A2237' }}>
				<Container paddingY={4}>
					<Footer />
				</Container>
			</Box>
		</Box>
	);
}
