const pages = {
	landings: [
		{
			title: 'Mobile App',
			href: '/mobile-app',
		},
		{
			title: 'Desktop App',
			href: '/desktop-app',
		},
		{
			title: 'Startup',
			href: '/startup',
		},
		{
			title: 'Expo',
			href: '/expo',
		},
		{
			title: 'Marketing',
			href: '/',
		},
		{
			title: 'Service',
			href: '/service',
		},
		{
			title: 'Enterprise',
			href: '/enterprise',
		},
		{
			title: 'Cloud Hosting',
			href: '/cloud-hosting',
		},
		{
			title: 'Design Company',
			href: '/design-company',
		},
		{
			title: 'Web Basic',
			href: '/web-basic',
		},
		{
			title: 'Overview',
			href: '/home',
		},
		{
			title: 'Agency',
			href: '/agency',
		},
		{
			title: 'E-Learning',
			href: '/e-learning',
		},
		{
			title: 'Coworking',
			href: '/coworking',
		},
		{
			title: 'Rental',
			href: '/rental',
		},
		{
			title: 'Job Listing',
			href: '/job-listing',
		},
		{
			title: 'Logistics',
			href: '/logistics',
		},
		{
			title: 'E-commerce',
			href: '/e-commerce',
		},
	],
	company: [
		{
			title: 'Career Lising',
			href: '/career-listing',
		},
		{
			title: 'Job Opening',
			href: '/career-opening',
		},
		{
			title: 'Customer Stories',
			href: '/customers',
		},
		{
			title: 'Hire Us',
			href: '/hire-us',
		},
		{
			title: 'About: Startup',
			href: '/about-side-cover',
		},
		{
			title: 'About',
			href: '/about',
		},
		{
			title: 'Pricing',
			href: '/pricing',
		},
		{
			title: 'Terms',
			href: '/company-terms',
		},
		{
			title: 'Contact: Map',
			href: '/contact-sidebar-map',
		},
		{
			title: 'Contact: Minimal',
			href: '/contact-page-cover',
		},
		{
			title: 'Contact: Startup',
			href: '/contact-page',
		},
	],
	account: [
		{
			title: 'General Settings',
			href: '/account-general',
		},
		{
			title: 'Security Settings',
			href: '/account-security',
		},
		{
			title: 'Notifications Options',
			href: '/account-notifications',
		},
		{
			title: 'Billing Options',
			href: '/account-billing',
		},
		{
			title: 'Sign up: Simple',
			href: '/signup-simple',
		},
		{
			title: 'Sign up: Cover',
			href: '/signup-cover',
		},
		{
			title: 'Sign in: Simple',
			href: '/signin-simple',
		},
		{
			title: 'Sign in: Cover',
			href: '/signin-cover',
		},
		{
			title: 'Password: Simple',
			href: '/password-reset-simple',
		},
		{
			title: 'Password: Cover',
			href: '/password-reset-cover',
		},
	],
	secondary: [
		{
			title: 'Help Center: Overview',
			href: '/help-center',
		},
		{
			title: 'Help Center: Article',
			href: '/help-center-article',
		},
		{
			title: 'FAQ',
			href: '/faq',
		},
		{
			title: 'Error 404: Simple',
			href: '/not-found',
		},
		{
			title: 'Error 404: Cover',
			href: '/not-found-cover',
		},
		{
			title: 'Career Page Minimal',
			href: '/career-listing-minimal',
		},
	],
	blog: [
		{
			title: 'Reach View',
			href: '/blog-reach-view',
		},
		{
			title: 'Search',
			href: '/blog-search',
		},
		{
			title: 'Newsroom',
			href: '/blog-newsroom',
		},
		{
			title: 'Article',
			href: '/blog-article',
		},
	],
	portfolio: [
		{
			title: 'Grid View',
			href: '/portfolio-grid',
		},
		{
			title: 'Masonry',
			href: '/portfolio-masonry',
		},
		{
			title: 'Basic',
			href: '/portfolio-page',
		},
		{
			title: 'Parallax',
			href: '/agency',
		},
	],
};

export default pages;
