import React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { Blue } from 'components';

export default function BestInClass() {
	return (
		<Box
			sx={{
				py: { xs: 6, sm: 8, md: 10 },
				textAlign: 'center',
				background: 'linear-gradient(#F7FAFF 0%, #E3EDFF 100%)',
			}}
		>
			<Container
				sx={{
					margin: 'auto',
					maxWidth: '1500px !important',
					justifyContent: 'center',
					display: 'flex',
				}}
			>
				<Stack spacing={6} width="80%">
					<Stack spacing={3}>
						<Typography variant="h4" fontWeight={800} textAlign="left">
							<Blue>No More Complications</Blue>
						</Typography>
						<Stack direction="row" spacing={2}>
							<Typography textAlign="left">
								DedCool&apos;s customer service team no longer had to worry
								about escalating customer issues when directing them to SavedBy.
								The transition to SavedBy was smooth and straightforward,
								eliminating the fear of creating more complications for
								customers during the resolution process.
							</Typography>
						</Stack>
					</Stack>
					<Stack spacing={3} alignItems="center">
						<Typography
							variant="h4"
							fontWeight={800}
							sx={{ width: { xs: '100%' }, textAlign: 'left' }}
						>
							<Blue>Streamlining DedCool&apos;s Workflows</Blue>
						</Typography>
						<Stack direction="row" spacing={2}>
							<Typography textAlign="left">
								One of the key advantages of SavedBy is its ability to simplify
								the claim filing process. DedCool&apos;s customer service team
								found it remarkably easy to file claims on behalf of customers,
								ensuring that issues were resolved without unnecessary delays or
								complications. Frictionless and quick.
							</Typography>
						</Stack>
					</Stack>
					<Stack spacing={3}>
						<Typography variant="h4" fontWeight={800} textAlign="left">
							<Blue>Summary</Blue>
						</Typography>
						<Stack direction="row" spacing={2}>
							<Typography textAlign="left">
								DedCool&apos;s transition to SavedBy has resulted in improved
								customer service operations, a streamlined claims process, and
								enhanced customer experiences. Their collaboration with the
								SavedBy team has been characterized by responsiveness and
								efficiency, and DedCool looks forward to continued success with
								SavedBy as a trusted partner.
							</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
}
