import { useMediaQuery, useTheme } from '@mui/material';

export default function useBreakpoint() {
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.up('xs'));
	const isSm = useMediaQuery(theme.breakpoints.up('sm'));
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const isLg = useMediaQuery(theme.breakpoints.up('lg'));
	const isXl = useMediaQuery(theme.breakpoints.up('xl'));
	const isXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
	const isSmOnly = useMediaQuery(theme.breakpoints.only('sm'));
	const isMdOnly = useMediaQuery(theme.breakpoints.only('md'));
	const isLgOnly = useMediaQuery(theme.breakpoints.only('lg'));

	return {
		isXs,
		isSm,
		isMd,
		isLg,
		isXl,
		isXsOnly,
		isSmOnly,
		isMdOnly,
		isLgOnly,
	};
}
