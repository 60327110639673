import React from 'react';
import {
	Box,
	Card,
	CardMedia,
	Container,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import { Blue, Br, SectionTitle } from 'components';

export default function FromTheClient() {
	return (
		<Container
			sx={{
				textAlign: 'center',
				pb: 10,
			}}
		>
			<Grid container spacing={{ xs: 4, sm: 6, md: 10, lg: 8 }}>
				<Grid item xs={12}>
					<Stack spacing={2}>
						<SectionTitle>FROM THE CLIENT</SectionTitle>

						<Typography
							variant="h4"
							textAlign="center"
							fontWeight={800}
							px={{ xs: 0, sm: 4, md: 10 }}
						>
							&quot;The biggest advantage we’ve seen so far has been the{' '}
							<Blue>communication & sense of urgency</Blue> around our
							<Br lg /> claims from the SavedBy team, and how the solutions
							<Br lg /> have matched the expectations they have set.&quot;
						</Typography>
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Card sx={{ aspectRatio: '1', borderRadius: 10 }}>
						<CardMedia component="img" src="AxeQuoteImage.png" />
					</Card>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack spacing={4} height="100%" justifyContent="center">
						<Typography textAlign="left">
							”SavedBy saved our customer support team time and frustration
							dealing with carriers to get issues resolved.{' '}
							<b>
								We’ve been <Blue px={0}>really impressed</Blue> by how much our
								expectations have matched the reality.
							</b>
							<Br xs />
							<Br xs /> The Founders met with us to understand our business
							procedures, and rather than expect us to change procedures for
							them, adjusted how they work to fit our needs.{' '}
							<b>
								They were able to <Blue px={0}>quickly make adjustments</Blue>{' '}
								on their end to match our business practices
							</b>{' '}
							and allow us to deliver the above and beyond customer experience
							that our customers expect and deserve.”
						</Typography>

						<Box
							sx={{
								borderLeftStyle: 'solid',
								borderLeftColor: '#38B6FF',
								borderLeftWidth: 4,
								textAlign: 'left',
								py: 1,
								pl: 2,
							}}
						>
							<Typography variant="h5">
								<b>Cory Martin</b>
							</Typography>
							<Typography>IT Specialist at Axe & Sledge</Typography>
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	);
}
