import { Stack } from '@mui/material';
import React from 'react';
import Hero from './components/Hero';
import { ShippingIssuesLogos } from './components/ShippingIssuesLogos';
import TrustedByThousands from './components/TrustedByThousands';
import FastResolutions from './components/FastResolutionsCustomer';
import ProtectYourOrders from './components/ProtectYourOrders';

export default function CustomersView() {
	return (
		<Stack>
			<Hero />

			<ShippingIssuesLogos />

			<TrustedByThousands />

			<FastResolutions />

			<ProtectYourOrders />
		</Stack>
	);
}
