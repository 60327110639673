/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

const mock = [
	{
		title: 'Lost',
		subtitle:
			'Lost packages happen. Mailmen fail. If you think your package is lost, just file a claim and we will get to the bottom of it.',
		icon: WrongLocationIcon,
	},
	{
		title: 'Stolen',
		subtitle:
			'Package Pirates are out there. Sadly, everyone nowadays knows the feeling of coming home to an unexpected empty porch. Don’t worry- just file your claim and we’ll handle the rest.',
		icon: LocalPoliceIcon,
	},
	{
		title: 'Damaged',
		subtitle:
			'Broken Glass? Lava (lamp) spewing? You guessed it… Just file that claim and we’ll make it right',
		icon: BrokenImageIcon,
	},
];

const Services = () => {
	const theme = useTheme();
	return (
		<Box>
			<Box marginBottom={6}>
				<Typography
					variant="h4"
					color="text.primary"
					align={'center'}
					gutterBottom
					sx={{
						fontWeight: 700,
					}}
				>
					Quick, Easy, Low Stress Claims
				</Typography>
				<Typography
					variant="h6"
					component="p"
					color="text.secondary"
					sx={{ fontWeight: 400 }}
					align={'center'}
				>
					Need to file a claim? We got you covered!
				</Typography>
			</Box>

			<Grid container spacing={2}>
				{mock.map((item, i) => (
					<Grid item xs={12} md={4} key={i}>
						<Box width={1} height={1}>
							<Box
								display={'flex'}
								flexDirection={'column'}
								alignItems={'center'}
							>
								<Box
									component={item.icon}
									width={60}
									height={60}
									marginBottom={2}
									color={theme.palette.primary.main}
								/>
								<Typography
									variant={'h6'}
									gutterBottom
									sx={{ fontWeight: 500 }}
									align={'center'}
								>
									{item.title}
								</Typography>
								<Typography align={'center'} color="text.secondary">
									{item.subtitle}
								</Typography>
							</Box>
						</Box>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default Services;
