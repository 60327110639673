import React from 'react';
import { Box, Card, CardMedia, Container, Grid, Stack } from '@mui/material';
import { Blue, Br, SectionHeader, SectionTitle } from 'components';

export default function Hero() {
	return (
		<Box
			sx={{
				pt: { xs: 10, md: 15 },
				pb: { xs: 6, md: 10 },
				background: 'linear-gradient(#2A395F 0%, #1A2237 100%)',
				color: 'white',
			}}
		>
			<Container>
				<Grid container spacing={{ xs: 4, md: 10 }} direction="row-reverse">
					<Grid item xs={12} md={6}>
						<Stack
							spacing={{ xs: 1, md: 4 }}
							height="100%"
							justifyContent="center"
						>
							<SectionTitle textAlign="left" pt={0}>
								CASE STUDY
							</SectionTitle>

							<SectionHeader
								textAlign="left"
								color="white"
								fontSize={{ xs: 36, sm: 44, lg: 48 }}
								lineHeight={{ md: '54px', xs: '46px' }}
								mt="8px !important"
							>
								Elevating the <Br xs />
								Customer Experience:
								<Blue>
									<Br xs />
									Pack Leashes
								</Blue>
							</SectionHeader>

							<img
								src="PACKLOGO.svg"
								alt="Axe & Sledge Logo"
								style={{
									width: 180,
									objectFit: 'content',
									marginTop: '24px',
									marginBottom: '16px',
								}}
							/>
						</Stack>
					</Grid>
					<Grid item xs={12} md={6}>
						<Card sx={{ borderRadius: 10, width: '100%' }}>
							<CardMedia component="img" src="PackHeroImage.png" />
						</Card>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
