import React from 'react';
import Slider from 'react-slick';
import { Box, Stack } from '@mui/material';
import { Image } from 'mui-image';
import { useBreakpoint } from 'components';

const CustomerLoyaltyReviews = () => {
	const bp = useBreakpoint();
	/** @type {import("react-slick").Settings} */
	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		arrows: false,
		centerMode: true,
		centerPadding: 0,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1.4,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		// {sm: x, md: y}
		<Box sx={{ height: bp.isSm ? 500 : 275, width: bp.isSm ? 500 : 300 }}>
			<Slider {...settings}>
				<Stack
					justifyContent="center"
					alignItems="center"
					sx={{ p: { xs: 0, sm: 1, md: 3 } }}
				>
					<Image src={'/1Star.svg'} alt="1 Star" />
				</Stack>
				<Stack
					justifyContent="center"
					alignItems="center"
					sx={{ p: { xs: 0, sm: 1, md: 3 } }}
				>
					<Image src={'/5Star.svg'} alt="5 Star" />
				</Stack>
			</Slider>
		</Box>
	);
};

export default CustomerLoyaltyReviews;
