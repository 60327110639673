import React from 'react';
import { Stack, Typography, Container } from '@mui/material';
import { Image } from 'mui-image';
import { Blue, BookButton, GetStartedButton } from 'components';

export default function Hero() {
	return (
		<Container sx={{ pt: { md: 10, xs: 2 } }}>
			<Stack
				justifyContent="space-between"
				direction={{ md: 'row' }}
				alignItems="center"
			>
				<Stack maxWidth={{ xs: 1, md: '50%' }}>
					<Typography
						gutterBottom
						color="#1A2237"
						variant="h2"
						fontWeight={800}
						textAlign={{ xs: 'center', md: 'left' }}
						pt={{ xs: 5, md: 0 }}
					>
						Stop <Blue>losing money</Blue>
						<br />
						on package issues.
					</Typography>

					<Typography
						variant="h6"
						fontWeight={400}
						textAlign={{ xs: 'center', md: 'left' }}
					>
						With SavedBy Package Protection, we’ll handle all your shipping
						challenges so you can start saving time and money while building
						customer loyalty.
					</Typography>

					<Stack
						justifyContent="space-evenly"
						alignItems="center"
						direction="row"
						mt={4}
						mb={{ xs: 4, sm: 8 }}
					>
						<BookButton />

						<GetStartedButton />
					</Stack>
				</Stack>
				<Stack width={{ xs: '100%', md: '30rem' }}>
					<Image
						shift="top"
						easing="ease-out"
						distance={400}
						src={'/Phone.png'}
						style={{
							width: '100%',
							height: 'auto',
						}}
					/>
				</Stack>
			</Stack>
		</Container>
	);
}
