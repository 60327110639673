import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
	Box,
	Card,
	CardMedia,
	Stack,
	Typography,
	Container,
} from '@mui/material';
import CustomerLoyaltyReviews from './CustomerLoyaltyReviews';
import {
	Br,
	Blue,
	SectionHeader,
	SectionText,
	SectionTitle,
	useBreakpoint,
} from 'components';

const BuildCustomerLoyalty = () => {
	const bp = useBreakpoint();

	return (
		<Box pb={{ xs: 7 }}>
			<Container>
				<Box>
					<SectionTitle>BUILD CUSTOMER LOYALTY</SectionTitle>
					<SectionHeader>
						{bp.isMd
							? 'Happy customers\nguaranteed—even when\nthings go wrong.'
							: 'Happy customers\nguaranteed—even\nwhen things go\nwrong.'}
					</SectionHeader>

					<Stack
						alignItems="center" // Adjust layout for mobile
						textAlign="center"
						sx={{
							display: { xs: 'block', md: 'flex' },

							pt: { xs: 0, md: 3 },
						}}
					>
						<Card sx={{ mt: { xs: 3.5, md: 1 } }}>
							<Stack>
								<CardMedia
									component="video"
									autoPlay
									loop
									muted
									playsInline
									sx={{
										height: 'auto',
										width: '100%',
										maxWidth: 800,
									}}
									image={'/Theft-video.mp4'}
								/>
								<Stack
									sx={{
										position: 'absolute',
										p: { xs: 1, md: 2 },
										textAlign: 'left',
									}}
								>
									<Typography
										variant="h6"
										color="white"
										fontWeight={700}
										fontSize={{ xs: 14, md: 24 }}
									>
										260M+ packages were stolen last year by porch pirates.
									</Typography>
									<Typography
										variant="subtitle1"
										color="white"
										fontWeight={500}
										fontSize={{ xs: 12, sm: 16 }}
									>
										That’s nearly one for every American.
									</Typography>
								</Stack>
							</Stack>
						</Card>
						<Container maxWidth="md" sx={{ px: '0px !important' }}>
							<Typography
								color="#1A2237"
								gutterBottom
								pt={4}
								mb={0}
								fontSize={{ xs: 24, md: 28 }} // Adjust font size for mobile
								fontWeight={950}
							>
								Bad shipping experiences
								<Br xs md={false} /> lead <Br md />
								customers to <Br xs sm={false} />
								<Blue>rethink purchasing</Blue> from <Br xs />a brand 75% of the
								time.
							</Typography>

							<SectionText width={bp.isMd ? '95%' : '100%'}>
								No store should take one-star reviews at the
								<Br xs sm={false} /> fault of their shipping carriers. SavedBy
								<Br xs={false} md /> turns shipping issues into quickly solved,
								positive experiences for your customer.
								<Br xs={false} md /> Why shouldn&apos;t every customer have a
								5-star experience?
							</SectionText>
						</Container>
					</Stack>
					{/*------------------------*/}
					<Box
						display="flex"
						justifyContent="center"
						maxHeight={300}
						sx={{ mt: bp.isLg ? 8 : 4, mb: bp.isLg ? 4 : 0 }}
					>
						{!bp.isLg ? (
							// Render this section only on mobile
							<CustomerLoyaltyReviews />
						) : (
							// Render this section only on desktop
							<Box display="flex" alignItems="center" flexDirection="row">
								<Box
									width={1}
									display="flex"
									flexDirection={bp.isLg ? 'row' : 'column'}
								>
									<LazyLoadImage src={'/1Star.svg'} />
									{bp.isLg && <LazyLoadImage src={'/Arrow.svg'} />}
									<LazyLoadImage src={'/5Star.svg'} />
								</Box>
							</Box>
						)}
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default BuildCustomerLoyalty;
