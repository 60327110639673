import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Container,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import {
	Br,
	FileClaimButton,
	SectionHeader,
	SectionText,
	SectionTitle,
	useBreakpoint,
} from 'components';
import React from 'react';
import PropTypes from 'prop-types';

export default function ProtectYourOrders() {
	return (
		<Box
			sx={{
				pb: 12,
				background: 'linear-gradient(#F7FAFF 0%, #E3EDFF 100%)',
				textAlign: 'center',
			}}
		>
			<Container>
				<Stack sx={{ mt: 2 }} spacing={4} alignItems="center">
					<SectionTitle>PROTECT YOUR ORDERS</SectionTitle>
					<SectionHeader
						fontSize={{
							xs: '32px !important',
							sm: '36px !important',
							md: '66px !important',
						}}
						mt="8px !important"
					>
						Quick, Easy, Low Stress
						<Br sm /> Claims
					</SectionHeader>

					<SectionText>Need to file a claim? We got you covered!</SectionText>

					<FileClaimButton mt="8px !important" />

					<Grid
						container
						rowSpacing={2}
						columnSpacing={{ md: 2 }}
						textAlign="left"
					>
						<Grid item xs={12} md={6} sx={{ display: 'flex' }}>
							<Card sx={{ minHeight: 300, p: 2, borderRadius: 10 }}>
								<CardContent>
									<Stack spacing={3}>
										<Stack direction="row" alignItems="center" spacing={2}>
											<img src="/Lost.svg" width={100} height={100} />
											<Typography variant="h5" fontWeight={800}>
												Lost
											</Typography>
										</Stack>
										<Typography>
											Lost packages happen. Mailmen fail. If you think your
											package is lost, just file a claim and we will get to the
											bottom of it.
										</Typography>
										<Card sx={{ aspectRatio: '1', borderRadius: 10 }}>
											<CardMedia
												component="img"
												autoPlay
												loop
												muted
												playsInline
												sx={{
													borderRadius: 10,
													objectFit: 'cover',
													height: '100%',
												}}
												image={'/Lost2.png'}
											/>
										</Card>
									</Stack>
								</CardContent>
							</Card>
						</Grid>
						<Grid container item xs={12} md={6} spacing={2}>
							<Grid item xs={12} sx={{ display: 'flex' }}>
								<Card sx={{ minHeight: 300, p: 2, borderRadius: 10 }}>
									<CardContent>
										<Stack spacing={3}>
											<Stack direction="row" alignItems="center" spacing={2}>
												<img src="/Theft.svg" width={100} height={100} />
												<Typography variant="h5" fontWeight={800}>
													Stolen
												</Typography>
											</Stack>
											<Typography>
												Package Pirates are out there. Sadly, everyone nowadays
												knows the feeling of coming home to an unexpected empty
												porch. Don’t worry - just file your claim and we’ll
												handle the rest.
											</Typography>
										</Stack>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex' }}>
								<Card sx={{ minHeight: 275, p: 2, borderRadius: 10 }}>
									<CardContent>
										<Stack spacing={3}>
											<Stack direction="row" alignItems="center" spacing={2}>
												<img src="/Damaged.svg" width={100} height={100} />
												<Typography variant="h5" fontWeight={800}>
													Damaged
												</Typography>
											</Stack>
											<Typography>
												Broken Glass? Lava (lamp) spewing? You guessed it… Just
												file that claim and we’ll make it right.
											</Typography>
										</Stack>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<PercentageCard />
						</Grid>
					</Grid>
				</Stack>
			</Container>
		</Box>
	);
}

export function PercentageCard() {
	const bp = useBreakpoint();
	return (
		<Card sx={{ p: 2 }}>
			<Grid
				container
				justifyContent={{ xs: 'space-between', md: 'space-evenly' }}
			>
				<DataPoint number="100%" label={`Hassle-free claims process`} />
				<DataPoint
					number="22h"
					label={`Claims dealt with in less than 
          24 hours on average`}
				/>
				<DataPoint
					number="195"
					label={`Countries covered. (yep, that’s all of them)`}
					bp={bp}
				/>
			</Grid>
		</Card>
	);
}

function DataPoint({ number, label }) {
	return (
		<Grid
			container
			item
			xs={12}
			md={3}
			pb={2}
			alignItems={{ xs: 'center', md: 'start' }}
			textAlign={{ xs: 'left', md: 'center' }}
			justifyContent="flex-start"
		>
			<Grid item xs={5} md={12}>
				<Typography
					color={'#0DB2FA'}
					fontSize={{ md: 90, xs: 44 }}
					fontWeight={700}
					textAlign={{ xs: 'left', md: 'center' }}
				>
					{number}
				</Typography>
			</Grid>
			<Grid item xs={7} md={12} height={{ md: '100%' }}>
				<Typography gutterBottom fontSize={{ md: 16, xs: 14 }}>
					{label}
				</Typography>
			</Grid>
		</Grid>
	);
}
DataPoint.propTypes = {
	number: PropTypes.string,
	label: PropTypes.string,
};
