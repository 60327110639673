import React from 'react';
import { CircularProgress, Dialog } from '@mui/material';
import PropTypes from 'prop-types';

export default function LoadingIndicator({ open }) {
	return <Dialog PaperComponent={CircularProgress} open={!!open} />;
}

LoadingIndicator.propTypes = {
	open: PropTypes.bool.isRequired,
};
