import { Container, Stack } from '@mui/material';
import React from 'react';
import FlexContainer from './FlexContainer/FlexContainer';
import {
	BookButton,
	Br,
	SectionHeader,
	SectionText,
	SectionTitle,
	useBreakpoint,
} from 'components';

const SavedByHasYouCovered = () => {
	const bp = useBreakpoint();

	return (
		<Container sx={{ pb: { xs: 3, md: 1 }, px: 0 }}>
			<SectionTitle>SAVEDBY HAS YOU COVERED</SectionTitle>

			<Container sx={{ px: 0 }} maxWidth="md" display="flex">
				<SectionHeader color="white">
					{bp.isMd
						? 'Rest easy. We’ll handle all\nyour shipping-related\nclaims and issues.'
						: 'Rest easy.\nWe’ll handle all your\nshipping-related\nclaims and issues.'}
				</SectionHeader>
			</Container>
			<Stack justifyContent="center">
				<SectionText color="white" width="100%">
					Get 24/7 support and coverage for every
					<Br xs sm={false} /> shipping issue and rest easy.
				</SectionText>
				<BookButton mt={1} />
			</Stack>

			<FlexContainer />
		</Container>
	);
};

export default SavedByHasYouCovered;
