import React, { useState } from 'react';
import { Button, CardContent, Collapse, Stack, Stepper } from '@mui/material';
import PropTypes from 'prop-types';
import HistoryStep from './HistoryStep';

function TrackingHistory({ tracking_history }) {
	const [collapsed, setCollapsed] = useState(true);

	const previewSteps = tracking_history.slice(0, 3);
	const remainingSteps = tracking_history.slice(3);
	return (
		<CardContent sx={{ px: 6 }}>
			<Stepper orientation="vertical">
				{previewSteps.map((h, i) => (
					<HistoryStep key={h.object_id} i={i} step={h} />
				))}
				{remainingSteps.length && (
					<Stack spacing={2}>
						<Collapse in={!collapsed}>
							{remainingSteps.map((h, i) => (
								<HistoryStep
									key={h.object_id}
									i={i + previewSteps.length}
									step={h}
									last={i === remainingSteps.length - 1}
								/>
							))}
						</Collapse>

						<Button variant="text" onClick={() => setCollapsed((c) => !c)}>
							{collapsed ? 'Show More' : 'Show Less'}
						</Button>
					</Stack>
				)}
			</Stepper>
		</CardContent>
	);
}

TrackingHistory.propTypes = {
	tracking_history: PropTypes.array,
};

export default TrackingHistory;
