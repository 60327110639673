import React from 'react';
import { Error } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export default function ErrorView() {
	return (
		<Box
			sx={{
				textAlign: 'center',
				position: 'absolute',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%,-50%)',
			}}
		>
			<Error fontSize="large" />
			<Typography>There was an error fetching your tracking info</Typography>
		</Box>
	);
}
