import React from 'react';
import Slider from 'react-slick';
import { Stack, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { SectionTitle, useBreakpoint } from 'components';

const MerchantReviews = () => {
	const bp = useBreakpoint();

	const testimonials = [
		{
			id: 1,
			message: bp.isMd
				? '"I have been loving SavedBy! Feels so\ngood to refer customers to you guys and\nknow they will be taken care of!"'
				: '"I have been loving SavedBy! Feels so good to refer customers to you guys and know they will be taken care of!"',
			name: '— Gabriela H.,',
			company: 'CX at DedCool',
		},
		{
			id: 2,
			message: bp.isMd
				? '"Our customers love that they get the\ncustomer experience from our team, and\nthe resolution they expect from\nSavedBy."'
				: '"Our customers love that they get the customer experience from our team, and the resolution they expect from SavedBy."',
			name: '— Cory M.,',
			company: 'IT Specialist at Axe & Sledge',
		},
		{
			id: 3,
			message: bp.isMd
				? '"SavedBy has literally Saved Us! Their\nteam has been nothing short of\nexceptional. They are quick to get these\nissues resolved for our customers."'
				: '"SavedBy has literally Saved Us! Their team has been nothing short of exceptional. They are quick to get these issues resolved for our customers."',
			name: '— Myles B.,',
			company: 'CMO at Soul Drops',
		},
	];

	const CustomPrevArrow = (props) => {
		const { onClick } = { ...props };
		return (
			<Stack
				sx={{
					position: 'absolute',
					top: '30%',
					left: bp.isLg ? '1%' : bp.isMd ? '1%' : '0%',
					cursor: 'pointer',
				}}
				onClick={onClick}
			>
				<KeyboardArrowLeft sx={{ fontSize: '4rem', color: '#38B6FF' }} />
			</Stack>
		);
	};

	const CustomNextArrow = (props) => {
		const { onClick } = { ...props };
		return (
			<Stack
				sx={{
					position: 'absolute',
					top: '30%',
					right: bp.isLg ? '1%' : bp.isMd ? '1%' : '0%',
					cursor: 'pointer',
				}}
				onClick={onClick}
			>
				<KeyboardArrowRight sx={{ fontSize: '4rem', color: '#38B6FF' }} />
			</Stack>
		);
	};

	const settings = {
		arrows: bp.isMd ? true : false,
		dots: true,
		infinite: true,
		speed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: true,
		pauseOnDotsHover: true,
		nextArrow: <CustomNextArrow />,
		prevArrow: <CustomPrevArrow />,
	};

	return (
		<Stack>
			<SectionTitle>WHAT OUR MERCHANTS ARE SAYING</SectionTitle>
			<Stack sx={{ mt: 3, mb: { xs: 3, md: 0 } }}>
				<Slider {...settings}>
					{testimonials.map((testimonial) => (
						<Stack key={testimonial.id} sx={{ mb: { xs: 0, sm: 3 } }}>
							<Typography
								sx={{
									textAlign: 'center',
									fontSize: bp.isLg ? '42px' : bp.isMd ? '40px' : '18px',
									fontWeight: 800,
									lineHeight: '1.2',
								}}
								whiteSpace="break-spaces"
							>
								{testimonial.message}
							</Typography>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="center"
								sx={{ mt: 2, mb: { xs: 1, sm: 0 } }}
							>
								<Typography style={{ textAlign: 'center', fontWeight: 900 }}>
									{testimonial.name}
								</Typography>
								<Typography style={{ textAlign: 'center' }}>
									&nbsp;{testimonial.company}
								</Typography>
							</Stack>
						</Stack>
					))}
				</Slider>
			</Stack>
		</Stack>
	);
};

export default MerchantReviews;
