import React from 'react';
import { CardContent, CardMedia } from '@mui/material';
import PropTypes from 'prop-types';

function LogoContainer({ mid }) {
	return (
		<CardContent sx={{ p: 4, ...style.logoCard }}>
			<CardMedia
				component="img"
				sx={{ px: 14 }}
				src={`https://cdn.savedby.io/logos/merchants/${mid}.png`}
			/>
		</CardContent>
	);
}

const style = {
	card: {
		bgcolor: 'white',
		textColor: 'black',
		dividerColor: 'gray',
	},
	page: {
		bgcolor: false,
	},
	logoCard: {
		bgcolor: 'black',
	},
};

LogoContainer.propTypes = {
	mid: PropTypes.string,
};

export default LogoContainer;
