import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
	dsn: 'https://8a50a1614e0949ddaa9a8379a7580773@o1209587.ingest.sentry.io/6619625',
	integrations: [new BrowserTracing()],
	enabled: process.env.NODE_ENV === 'production',
	tracesSampleRate: 1.0,
});

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
