/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function Hero() {
	const theme = useTheme();

	return (
		<Grid container spacing={4}>
			<Grid item container alignItems={'center'} xs={12} md={6}>
				<Box pt={4}>
					<Box marginBottom={2}>
						<Typography
							variant="h2"
							color="white"
							sx={{
								fontWeight: 700,
							}}
						>
							File a Claim
						</Typography>
					</Box>
					<Box>
						<Typography variant="h6" color="white" sx={{ fontWeight: 400 }}>
							S*** happens. Filing a claim is quick and easy!
						</Typography>
					</Box>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box
					height={1}
					width={1}
					display={'flex'}
					justifyContent={'center'}
					alignItems={'center'}
				>
					<Box height={1} width={1} maxWidth={500}>
						<Box
							component={'img'}
							src={
								'https://assets.maccarianagency.com/svg/illustrations/drawkit-illustration6.svg'
							}
							width={1}
							height={1}
							sx={{
								filter:
									theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
							}}
						/>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}
