import { Container, Stack } from '@mui/material';
import {
	Blue,
	FileClaimButton,
	SectionHeader,
	SectionText,
	SectionTitle,
	useBreakpoint,
} from 'components';
import React from 'react';

import ProtectStepper from './ProtectStepper';

const FastResolutionsMobile = () => {
	const bp = useBreakpoint();

	return (
		<Container sx={{ py: 2 }}>
			<SectionTitle>FAST RESOLUTIONS</SectionTitle>

			<Stack textAlign="center" alignItems="center" sx={{ mt: 2 }} spacing={2}>
				<SectionHeader>
					{'Protect Your Order\nin'}
					<Blue> 3 Easy Steps</Blue>
				</SectionHeader>

				<SectionText width={bp.isSmOnly ? '90%' : '95%'}>
					SavedBy Package Protection will handle all of your shipping issues.
					We’ll re-order from the merchant you experienced the issue with, or
					issue a refund directly to you, and will always respond to all of your
					claims within 24 hours.
				</SectionText>
				<FileClaimButton />
				<ProtectStepper />
			</Stack>
		</Container>
	);
};

export default FastResolutionsMobile;

// height: 64px;
// width: 64px;
// border-radius: 100px;
// display: flex;
// justify-content: center;
// align-items: center;
// background-color: #38b6ff;
// border: 10px solid #d7f0ff;
// color: #fff;
// font-size: 42px;
// font-weight: 700;
// padding: 35px;
// text-align: center;
// margin: auto;
