import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

export default function LoadingView() {
	return (
		<Box
			sx={{
				textAlign: 'center',
				position: 'absolute',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%,-50%)',
			}}
		>
			<CircularProgress />
			<Typography>Fetching your package!</Typography>
		</Box>
	);
}
