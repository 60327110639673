import React from 'react';
import { Stack } from '@mui/material';
import Hero from './Hero';
import DataSection from './DataSection';
import KeyMetrics from './KeyMetrics';
import FromTheClient from './FromTheClient';
import BestInClass from './BestInClass';

export default function CaseStudyView() {
	return (
		<Stack>
			<Hero />
			<DataSection />
			<KeyMetrics />
			<FromTheClient />
			<BestInClass />
		</Stack>
	);
}
