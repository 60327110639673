import React from 'react';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { Blue, Br, useBreakpoint } from 'components';
import PropTypes from 'prop-types';

export default function BestInClass() {
	const bp = useBreakpoint();
	return (
		<Box
			sx={{
				py: { xs: 6, sm: 8 },
				textAlign: 'center',
				background: 'linear-gradient(#F7FAFF 0%, #E3EDFF 100%)',
			}}
		>
			<Container sx={{ margin: 'auto', maxWidth: '1500px !important' }}>
				<Typography variant="h4" fontWeight={800} mb={4}>
					<Blue>Best-in-Class</Blue>
					<Br xs />
					Shipping Protection &<Br xs />
					Customer Support
				</Typography>

				<Stack
					spacing={4}
					justifyContent={bp.isLg ? 'space-evenly' : 'center'}
					direction={{ xs: 'column', lg: 'row' }}
				>
					<Data title="Protected Orders" number="$2,000,000+" />
					<Data title="Claim Approval Rate" number="99%" />
					<Data title="$ Saved Per Claim" number="$125" />
					<Data title="Avg. Claim Resolution Time" number="<24hrs" />
				</Stack>
			</Container>
		</Box>
	);
}

function Data({ title, number }) {
	return (
		<Stack textAlign="left">
			<Typography variant="h6" fontWeight={800}>
				{title}
				<Divider sx={{ borderColor: 'black', pt: 1 }} />
			</Typography>
			<Blue fontSize={{ xs: 42, sm: 52, md: 60 }} fontWeight={800}>
				{number}
			</Blue>
		</Stack>
	);
}
Data.propTypes = {
	title: PropTypes.string,
	number: PropTypes.string,
};
