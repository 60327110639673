import React from 'react';
import { Box } from '@mui/material';

export default function TOSPDF() {
	return (
		<Box width="100%" height="100vh">
			<iframe
				style={{ width: '100%', height: '100%' }}
				src="/ServiceAgreement.docx.pdf"
			/>
		</Box>
	);
}
