import { responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import palette from './palette';

const getTheme = () =>
	responsiveFontSizes(
		createTheme({
			palette,
			shadows,
			typography: {
				fontFamily: '"Inter", sans-serif',
				button: {
					textTransform: 'none',
					fontWeight: 'medium',
				},
			},
			zIndex: {
				appBar: 1200,
				drawer: 1300,
			},
			components: {
				MuiButton: {
					variants: [
						{
							props: { variant: 'contained' },
							style: {
								width: 200,
								borderRadius: 60,
								fontWeight: 700,
								textTransform: 'uppercase',
								background: 'linear-gradient(135deg, #0DB2FA 0%, #0357FF 100%)',
							},
						},
						{
							props: { variant: 'outlined' },
							style: {
								width: 200,
								borderRadius: 60,
								fontWeight: 700,
								textTransform: 'uppercase',
								color: 'white',
								borderColor: 'primary',
							},
						},
					],
					styleOverrides: {
						root: {
							fontWeight: 400,
							borderRadius: 5,
							paddingTop: 10,
							paddingBottom: 10,
						},
						containedSecondary: { color: 'white' },
					},
				},
				MuiInputBase: {
					styleOverrides: {
						root: {
							borderRadius: 5,
						},
					},
				},
				MuiOutlinedInput: {
					styleOverrides: {
						root: {
							borderRadius: 5,
						},
						input: {
							borderRadius: 5,
						},
					},
				},
				MuiCard: {
					styleOverrides: {
						root: {
							borderRadius: 20,
						},
					},
				},
			},
		}),
	);

export default getTheme;
