import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useBreakpoint from './useBreakpoint';

export default function SectionText({ children, color, width }) {
	const bp = useBreakpoint();

	return (
		<Typography
			color={color || '#1A2237'}
			textAlign="center"
			fontWeight={500}
			fontSize={bp.isMd ? '20px' : '15px'}
			lineHeight={bp.isMd ? '28px ' : '22px'}
			whiteSpace="break-spaces"
			py={bp.isMd ? 3 : 2}
			m="auto !important"
			width={width || '75%'}
		>
			{children}
		</Typography>
	);
}

SectionText.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.string.isRequired]),
	color: PropTypes.string,
	width: PropTypes.string,
};
