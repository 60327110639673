import React from 'react';
import { Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { Blue, Br } from 'components';
import PropTypes from 'prop-types';

export default function DataSection() {
	return (
		<Container sx={{ py: 8 }}>
			<Grid container spacing={8}>
				<Grid item xs={12} md={6}>
					<Stack spacing={{ xs: 5, md: 10 }}>
						<Data
							title="Coverage Rate"
							number={'82%'}
							subtitle={
								"Packages Covered: ensuring that the majority of DedCool's customers have their shipments protected."
							}
						/>
						<Data
							title="Claim Approval Rate"
							number={'100%'}
							subtitle={
								'Claim Approval Rate: SavedBy has maintained a perfect record, approving all claims submitted by DedCool and their customers.'
							}
						/>
					</Stack>
				</Grid>

				<Grid item xs={12} md={6}>
					<Stack spacing={6}>
						<Typography variant="h4" fontWeight={900}>
							DedCool enhanced their <Br xs={false} lg />
							<Blue pl={0}>customer experience</Blue> by giving customers the
							option to cover their package at checkout.
						</Typography>
						<Typography mt="36px !important">
							DedCool is a forward-thinking fragrance company based in Los
							Angeles. They produce genderless fragrances and detergents
							(Dedtergent) that can be mixed and matched however a customer sees
							fit. This case study explores how DedCool leverages SavedBy to
							improve their customer service operations and enhance the overall
							customer experience.
						</Typography>

						<Typography
							variant="h4"
							gutterBottom
							fontWeight={800}
							mt="32px !important"
						>
							The Problem:
						</Typography>
						<Typography mt="16px !important">
							DedCool faced challenges in its customer service operations and
							customer experience when it came to package issues created by
							carriers.
						</Typography>

						<Typography
							variant="h4"
							gutterBottom
							fontWeight={800}
							mt="32px !important"
						>
							The Solution:
						</Typography>
						<Typography mt="16px !important">
							The company decided to implement SavedBy, known for its ease of
							use and efficiency in resolving customer issues related to lost,
							stolen, and damaged packages.
						</Typography>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	);
}

function Data({ title, number, subtitle }) {
	return (
		<Stack>
			<Typography variant="h5" fontWeight={800}>
				{title}
				<Divider sx={{ borderColor: 'black', pt: 1 }} />
			</Typography>
			<Blue fontSize={{ xs: 54, md: 75 }} fontWeight={800}>
				{number}
			</Blue>
			<Typography fontSize={18} variant="caption">
				{subtitle}
			</Typography>
		</Stack>
	);
}
Data.propTypes = {
	title: PropTypes.string,
	number: PropTypes.string,
	subtitle: PropTypes.string,
};
