import React from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import { NavItem } from './components';
import { FileClaimButton } from '../../../../components';
import { Box, IconButton, Stack, alpha, useTheme } from '@mui/material';

const Topbar = ({ onSidebarOpen, colorInvert = false }) => {
	const theme = useTheme();

	return (
		<Box
			display={'flex'}
			justifyContent={'space-between'}
			alignItems={'center'}
			width={1}
		>
			<Box
				display={'flex'}
				component="a"
				href="/"
				title="SavedBy"
				width={{ xs: 150, md: 250 }}
			>
				<Box
					component={'img'}
					src="https://cdn.savedby.io/SavedByLogo3.png"
					height={1}
					width={1}
				/>
			</Box>

			<Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
				{/* <Box>
					<NavItem
						title={'FAQ'}
						id={'landing-pages'}
						sx={{ fontWeight: 500 }}
						href="/"
						colorInvert={colorInvert}
					/>
				</Box> */}
				<Box marginLeft={10}>
					<NavItem
						title={'Policies'}
						href="/policies"
						colorInvert={colorInvert}
					/>
				</Box>
				<Box marginLeft={10}>
					<FileClaimButton width={200} />
				</Box>
			</Box>
			<Stack
				direction="row"
				pr={2}
				sx={{ display: { xs: 'flex', md: 'none' } }}
				alignItems={'center'}
				spacing={2}
			>
				<FileClaimButton width={175} />
				<IconButton
					onClick={() => onSidebarOpen()}
					aria-label="Menu"
					fontSize="large"
					sx={{
						borderRadius: 2,
						minWidth: 'auto',
						padding: 1,
						borderColor: alpha(theme.palette.divider, 0.2),
					}}
				>
					<MenuIcon color="primary" />
				</IconButton>
			</Stack>
		</Box>
	);
};

Topbar.propTypes = {
	onSidebarOpen: PropTypes.func,
	pages: PropTypes.object,
	colorInvert: PropTypes.bool,
};

export default Topbar;
