import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const Content = () => {
	return (
		<Container maxWidth="md">
			<Box>
				This Privacy Policy describes how savedby.io (the “Site” or “we”)
				collects, uses, and discloses your Personal Information when you visit
				or make a purchase from the Site.
				<br />
				<br />
				<Typography variant="h3">Contact</Typography>
				<br />
				After reviewing this policy, if you have additional questions, want more
				information about our privacy practices, or would like to make a
				complaint, please contact us by e-mail at support@savedby.io or by mail
				using the details provided below:
				<br />
				<br />
				2255 Sheridan BLVD, Unit C#246, Edgewater CO 80214, United States
				<br />
				<br />
				<Typography variant="h3">Collecting Personal Information</Typography>
				<br />
				When you visit the Site, we collect certain information about your
				device, your interaction with the Site, and information necessary to
				process your purchases. We may also collect additional information if
				you contact us for customer support. In this Privacy Policy, we refer to
				any information about an identifiable individual (including the
				information below) as “Personal Information”. See the list below for
				more information about what Personal Information we collect and why.
				<ul>
					<li>
						<u>Device information</u>
					</li>
					<ul>
						<li>
							<b>Purpose of collection:</b> to load the Site accurately for you,
							and to perform analytics on Site usage to optimize our Site.
						</li>
						<li>
							<b>Source of collection::</b> Collected automatically when you
							access our Site using cookies, log files, web beacons, tags, or
							pixels.
						</li>
						<li>
							<b>Disclosure for a business purpose:</b> shared with our email
							provider MailChimp.
						</li>
						<li>
							<b>Personal Information collected:</b> version of web browser, IP
							address, time zone, cookie information, what sites or products you
							view, search terms, and how you interact with the Site.
						</li>
					</ul>
				</ul>
				<ul>
					<li>
						<u>Order information</u>
					</li>
					<ul>
						<li>
							<b>Purpose of collection:</b>to provide products or services to
							you to fulfill our contract, to process your payment information,
							arrange for shipping, and provide you with invoices and/or order
							confirmations, communicate with you, screen our orders for
							potential risk or fraud, and when in line with the preferences you
							have shared with us, provide you with information or advertising
							relating to our products or services.
						</li>
						<li>
							<b>Source of collection::</b> collected from you.
						</li>
						<li>
							<b>Disclosure for a business purpose:</b> shared with our email
							provider MailChimp.
						</li>
						<li>
							<b>Personal Information collected:</b> name, billing address,
							shipping address, payment information (including credit card
							numbers, paypal, or any other accepted payment methods) email
							address, and phone number.
						</li>
					</ul>
				</ul>
				<ul>
					<li>
						<u>Customer support information</u>
					</li>
					<ul>
						<li>
							<b>Purpose of collection:</b> to provide customer support.
						</li>
						<li>
							<b>Source of collection::</b> collected from you.
						</li>
						<li>
							<b>Disclosure for a business purpose:</b> Gmail.
						</li>
						<li>
							<b>Personal Information collected:</b> any information submitted
							by you.
						</li>
					</ul>
				</ul>
				<Typography variant="h4">Minors</Typography>
				<br />
				The Site is not intended for individuals under the age of 18. We do not
				intentionally collect Personal Information from children. If you are the
				parent or guardian and believe your child has provided us with Personal
				Information, please contact us at the address above to request deletion.
				<br />
				<br />
				<Typography variant="h3">Sharing Personal Information</Typography>
				<br />
				We share your Personal Information with service providers to help us
				provide our services and fulfill our contracts with you, as described
				above. For example:
				<ul>
					<li>
						We use MailChimp to power our emails. You can read more about how
						MailChimp uses your Personal Information here:
						https://mailchimp.com/legal/
					</li>
					<li>
						We may share your Personal Information to comply with applicable
						laws and regulations, to respond to a subpoena, search warrant or
						other lawful request for information we receive, or to otherwise
						protect our rights.
					</li>
				</ul>
				<Typography variant="h3">Behavioral Advertising</Typography>
				<br />
				As described above, we use your Personal Information to provide you with
				targeted advertisements or marketing communications we believe may be of
				interest to you. For example:
				<ul>
					<li>
						We use Google Analytics to help us understand how our customers use
						the Site. You can read more about how Google uses your Personal
						Information here: https://www.google.com/intl/en/policies/privacy/.
						You can also opt-out of Google Analytics here:
						https://tools.google.com/dlpage/gaoptout.
					</li>
					<li>
						We share information about your use of the Site, your purchases, and
						your interaction with our ads on other websites with our advertising
						partners. We collect and share some of this information directly
						with our advertising partners, and in some cases through the use of
						cookies or other similar technologies (which you may consent to,
						depending on your location).
					</li>
				</ul>
				For more information about how targeted advertising works, you can visit
				the Network Advertising Initiative’s (“NAI”) educational page at
				https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
				<br />
				You can opt out of targeted advertising by:
				<ul>
					<li>FACEBOOK - https://www.facebook.com/settings/?tab=ads</li>
					<li>GOOGLE - https://www.google.com/settings/ads/anonymous</li>
				</ul>
				Additionally, you can opt out of some of these services by visiting the
				Digital Advertising Alliance’s opt-out portal at:
				https://optout.aboutads.info/.
				<br />
				<br />
				<Typography variant="h3">Using Personal Information</Typography>
				<br />
				We use your personal Information to provide our services to you, which
				includes: offering products for sale, processing payments, shipping and
				fulfillment of your order, and keeping you up to date on new products,
				services, and offers.
				<br />
				<br />
				<Typography variant="h4">Lawful basis</Typography>
				<br />
				Pursuant to the General Data Protection Regulation (“GDPR”), if you are
				a resident of the European Economic Area (“EEA”), we process your
				personal information under the following lawful bases:
				<ul>
					<li>Your consent;</li>
					<li>The performance of the contract between you and the Site;</li>
					<li>Compliance with our legal obligations;</li>
					<li>To protect your vital interests;</li>
					<li>To perform a task carried out in the public interest;</li>
					<li>
						For our legitimate interests, which do not override your fundamental
						rights and freedoms.
					</li>
				</ul>
				<br />
				<br />
				<Typography variant="h4">Retention</Typography>
				<br />
				When you place an order through the Site, we will retain your Personal
				Information for our records unless and until you ask us to erase this
				information. For more information on your right of erasure, please see
				the ‘Your rights’ section below.
				<br />
				<br />
				<Typography variant="h4">Automatic decision-making</Typography>
				<br />
				If you are a resident of the EEA, you have the right to object to
				processing based solely on automated decision-making (which includes
				profiling), when that decision-making has a legal effect on you or
				otherwise significantly affects you.
				<br />
				<br />
				We DO NOT engage in fully automated decision-making that has a legal or
				otherwise significant effect using customer data.
				<br /> <br />
				Services that include elements of automated decision-making include:
				<ul>
					<li>
						Temporary blacklist of IP addresses associated with repeated failed
						transactions. This blacklist persists for a small number of hours.
					</li>
					<li>
						Temporary blacklist of credit cards associated with blacklisted IP
						addresses. This blacklist persists for a small number of days.
					</li>
				</ul>
				<Typography variant="h3">Your rights</Typography>
				<br />
				<Typography variant="h4">GDPR</Typography>
				<br />
				If you are a resident of the EEA, you have the right to access the
				Personal Information we hold about you, to port it to a new service, and
				to ask that your Personal Information be corrected, updated, or erased.
				If you would like to exercise these rights, please contact us through
				the contact information above.
				<br />
				<br />
				<Typography variant="h4">CCPA</Typography>
				<br />
				If you are a resident of California, you have the right to access the
				Personal Information we hold about you (also known as the ‘Right to
				Know’), to port it to a new service, and to ask that your Personal
				Information be corrected, updated, or erased. If you would like to
				exercise these rights, please contact us through the contact information
				above. If you would like to designate an authorized agent to submit
				these requests on your behalf, please contact us at the address above.
				<br />
				<br />
				<Typography variant="h3">Cookies</Typography>
				<br />
				We do not currently collect any information via Cookies on our website.
				This is subject to change without notice.
				<br />
				<br />
				The length of time that a cookie remains on your computer or mobile
				device depends on whether it is a “persistent” or “session” cookie.
				Session cookies last until you stop browsing and persistent cookies last
				until they expire or are deleted. Most of the cookies we use are
				persistent and will expire between 30 minutes and two years from the
				date they are downloaded to your device.
				<br />
				<br />
				You can control and manage cookies in various ways. Please keep in mind
				that removing or blocking cookies can negatively impact your user
				experience and parts of our website may no longer be fully accessible.
				<br />
				<br />
				Most browsers automatically accept cookies, but you can choose whether
				or not to accept cookies through your browser controls, often found in
				your browser’s “Tools” or “Preferences” menu. For more information on
				how to modify your browser settings or how to block, manage or filter
				cookies can be found in your browser’s help file or through such sites
				as: www.allaboutcookies.org.
				<br />
				<br />
				Additionally, please note that blocking cookies may not completely
				prevent how we share information with third parties such as our
				advertising partners. To exercise your rights or opt-out of certain uses
				of your information by these parties, please follow the instructions in
				the “Behavioural Advertising” section above.
				<br />
				<br />
				<Typography variant="h4">Do Not Track</Typography>
				<br />
				Please note that because there is no consistent industry understanding
				of how to respond to “Do Not Track” signals, we do not alter our data
				collection and usage practices when we detect such a signal from your
				browser.
				<br />
				<br />
				<Typography variant="h4">Changes</Typography>
				<br />
				We may update this Privacy Policy from time to time in order to reflect,
				for example, changes to our practices or for other operational, legal,
				or regulatory reasons.
				<br />
				<br />
				<Typography variant="h4">Complaints</Typography>
				<br />
				As noted above, if you would like to make a complaint, please contact us
				by e-mail or by mail using the details provided under “Contact” above.
				<br />
				<br />
				If you are not satisfied with our response to your complaint, you have
				the right to lodge your complaint with the relevant data protection
				authority. You can contact your local data protection authority, or our
				supervisory authority here.
				<br />
				<br /> Last updated: 1/22/2023
			</Box>
		</Container>
	);
};

export default Content;
