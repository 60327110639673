/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
	Card,
	CardContent,
	InputAdornment,
	MenuItem,
	Stack,
} from '@mui/material';
import { Check, CheckCircle, Error as ErrorIcon } from '@mui/icons-material';

const validationSchema = yup.object({
	fullName: yup
		.string()
		.trim()
		.min(2, 'Please enter a valid full name')
		.max(50, 'Please enter a valid full name')
		.required('Please specify your full name'),
	message: yup.string().trim().required('Please specify your message'),
	email: yup
		.string()
		.trim()
		.email('Please enter a valid email address')
		.required('Email is required'),
});

export default function Form({
	merchantName,
	orderName,
	mid,
	customerName,
	email,
	_id,
	setLoading,
}) {
	const [confirmed, setConfirmed] = useState();
	const [error, setError] = useState(false);
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true,
	});

	const initialValues = {
		fullName: customerName,
		email: email,
		merchant: merchantName,
		orderNumber: orderName,
		type: '',
		details: '',
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			await fetch(`${process.env.REACT_APP_API_URL}/v1/new-claim`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					fullName: formik.values.fullName,
					email: formik.values.email,
					merchantName: formik.values.merchant,
					orderNumber: formik.values.orderNumber,
					type: formik.values.type,
					description: formik.values.details,
					mid,
					oid: Number(_id),
				}),
			})
				.then(async (r) => {
					if (r.status === 200) {
						return r;
					} else throw new Error(await r.text());
				})
				.then((r) => r.json())
				.then((r) => setConfirmed(r.claim._id))
				.finally(() => setLoading(false));

			return formik.values;
		} catch (err) {
			if (/already exists/.test(err.message)) {
				setError({
					code: 'already_exists',
				});
			} else if (/not covered/.test(err.message)) {
				setError({
					code: 'not_covered',
				});
			} else setError(true);
		}
		setLoading(false);
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
	});

	if (confirmed) {
		return (
			<Card>
				<CardContent sx={{ textAlign: 'center' }}>
					<Stack alignItems="center">
						<Check style={{ fontSize: 80, color: 'green' }} />
						Claim Submitted!
						<br />
						One of our reps will contact you via email within 1 business day.
					</Stack>
				</CardContent>
			</Card>
		);
	}

	if (error) {
		return (
			<Card>
				<CardContent sx={{ textAlign: 'center' }}>
					<Stack alignItems="center" spacing={4}>
						<ErrorIcon style={{ fontSize: 80, color: 'red' }} />
						{error?.code == 'already_exists' ? (
							<Typography>
								There is already a claim for this order.
								<br />
								<br />
								Our team will contact you in 1 business day from claim
								submission.
								<br /> If you have any questions or updates for this claim,
								please email us directly at support@savedby.io
							</Typography>
						) : error?.code == 'not_covered' ? (
							<Typography>
								Our records indicate this order is not covered by SavedBy.
								<br /> If you feel this is incorrect, please email us directly:
								support@savedby.io
							</Typography>
						) : (
							<Typography>
								There was an error submitting your claim. Please email us
								directly: support@savedby.io
							</Typography>
						)}
					</Stack>
				</CardContent>
			</Card>
		);
	}

	return (
		<Box>
			<Box marginBottom={2}>
				<Typography
					variant={'h4'}
					sx={{ fontWeight: 700 }}
					gutterBottom
					align={'center'}
				>
					Filing a claim is easy!
				</Typography>
				<Typography color="text.secondary" align={'center'}>
					Let us know the where, what and who, so we can make it right!
				</Typography>
			</Box>
			<Box
				maxWidth={600}
				margin={'0 auto'}
				component={'form'}
				onSubmit={onSubmit}
				sx={{
					'& .MuiOutlinedInput-root.MuiInputBase-multiline': {
						padding: 0,
					},
					'& .MuiOutlinedInput-input': {
						background: theme.palette.background.paper,
						padding: 2,
					},
				}}
			>
				<Grid container spacing={isMd ? 4 : 2}>
					<Grid item xs={12}>
						<Typography
							variant="subtitle1"
							color="text.primary"
							fontWeight={700}
							gutterBottom
						>
							Full name
						</Typography>
						<TextField
							disabled={customerName}
							placeholder="Your full name"
							variant="outlined"
							size="medium"
							name="fullName"
							fullWidth
							type="text"
							value={formik.values.fullName}
							onChange={formik.handleChange}
							error={formik.touched.fullName && Boolean(formik.errors.fullName)}
							helperText={formik.touched.fullName && formik.errors.fullName}
							InputProps={{
								endAdornment: customerName && (
									<InputAdornment position="end">
										<CheckCircle />
									</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<Typography
							variant="subtitle1"
							color="text.primary"
							fontWeight={700}
							gutterBottom
						>
							E-mail
						</Typography>
						<TextField
							disabled={email}
							placeholder="Your e-mail address"
							variant="outlined"
							size="medium"
							name="email"
							fullWidth
							type="email"
							value={formik.values.email}
							onChange={formik.handleChange}
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={formik.touched.email && formik.errors.email}
							InputProps={{
								endAdornment: email && (
									<InputAdornment position="end">
										<CheckCircle />
									</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<Typography
							variant="subtitle1"
							color="text.primary"
							fontWeight={700}
							gutterBottom
						>
							Merchant
						</Typography>
						<TextField
							disabled={merchantName}
							placeholder="The store the order is from"
							variant="outlined"
							name="merchant"
							fullWidth
							value={formik.values.merchant}
							onChange={formik.handleChange}
							error={formik.touched.merchant && Boolean(formik.errors.merchant)}
							helperText={formik.touched.merchant && formik.errors.merchant}
							InputProps={{
								endAdornment: merchantName && (
									<InputAdornment position="end">
										<CheckCircle />
									</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<Typography
							variant="subtitle1"
							color="text.primary"
							fontWeight={700}
							gutterBottom
						>
							Order Number
						</Typography>
						<TextField
							disabled={orderName}
							placeholder="Your Order Number"
							variant="outlined"
							name="orderNumber"
							fullWidth
							value={formik.values.orderNumber}
							onChange={formik.handleChange}
							error={
								formik.touched.orderNumber && Boolean(formik.errors.orderNumber)
							}
							helperText={
								formik.touched.orderNumber && formik.errors.orderNumber
							}
							InputProps={{
								endAdornment: orderName && (
									<InputAdornment position="end">
										<CheckCircle />
									</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={12}>
						<Typography
							variant="subtitle1"
							color="text.primary"
							fontWeight={700}
							gutterBottom
						>
							What Happened?
						</Typography>
						<TextField
							select
							placeholder="What kind of issue is this?"
							variant="outlined"
							name="type"
							fullWidth
							value={formik.values.type}
							onChange={formik.handleChange}
							error={formik.touched.type && Boolean(formik.errors.type)}
							helperText={formik.touched.type && formik.errors.type}
							required
						>
							{[
								'Stolen',
								'Lost/Missing',
								'Damaged',
								'Returned to Sender',
								'Other',
							].map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</TextField>
					</Grid>

					<Grid item xs={12}>
						<Typography
							variant="subtitle1"
							color="text.primary"
							fontWeight={700}
							gutterBottom
						>
							Detailed Explanation
						</Typography>
						<TextField
							placeholder="Explain any additional info..."
							variant="outlined"
							name="details"
							fullWidth
							multiline
							rows={4}
							required
							value={formik.values.details}
							onChange={formik.handleChange}
							error={formik.touched.details && Boolean(formik.errors.details)}
							helperText={formik.touched.details && formik.errors.details}
						/>
					</Grid>

					<Grid item container justifyContent="center" xs={12}>
						<Button
							variant="contained"
							type="submit"
							color="primary"
							size="large"
						>
							Submit Claim
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}
